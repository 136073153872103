import bus from "/src/utils/event_bus";
import { useSessionStore } from "@/stores/session";
import router from '/src/router'


// WATCHERS THAT LAUNCH ACTIONS FROM SESSION STATE CHANGES


export const init_session_watchers = function () {
    const session_store = useSessionStore()

    session_store.$subscribe((mutation, state) => {
        console.log("🍍👁️ Empty watchers for session store.")
    })

    bus.on("events/session/logout", () => {
        session_store.cleanSession()
        router.push("/login").catch(() => console.log("[!] Can't go to login. Maybe already in login page."))
    })

}