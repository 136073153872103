// THIS MODULE DOES NOT HOLD ANY BACKEND DATA
// EVERYTHING IS VIEWER FRONTEND STATE
// MAIN SOURCE OF SELECTED DATA IS selected.py MODULE
import { zones_from_viewer_bom } from "./viewer_helpers"
import { nameSort } from "/src/utils/basics";
import _ from "lodash"
import { defineStore } from 'pinia'
import { useSelectedStore } from "/src/stores/selected"
import { useCloudstorageStore } from "/src/stores/cloudstorage"

export const useViewerStore = defineStore('viewer', {
    state: () => ({
        // Mode
        designs_mode: false,
        pdf_mode: true,
        right_panel_mode: "details",
        level: "assembly",  // part, assembly, module, project (subassembly is also assembly)

        // Viewer state
        left_panel_dwg_number: 1,
        right_panel_cf_uuid: null,
        selected_parts_ifc_ids: [],
        selected_zones: [],

        // IFC.JS DATA
        selected_part_props: {},
        bom_loading_status: false,
        bom: [],
    }),
    getters: {
        resPackage: (state) => {
            // THIS IS NOT OK FOR level "project" BECAUSE YOU HAVE ALL FILES
            // AND YOU NEED TO FILTER TO HAVE ONLY "PROJECT_" FILES

            const cloudstorage_store = useCloudstorageStore()
            let pck = cloudstorage_store.packagesByNameAndLevel({
                name: "resources",
                level: state.level + "_design"
            })?.[0]
            return pck
        },
        heavyMetaPackage: (state) => {
            const cloudstorage_store = useCloudstorageStore()
            let pck = cloudstorage_store.packagesByNameAndLevel({
                name: "heavy_meta",
                level: state.level + "_design"
            })?.[0]
            return pck
        },
        parentResPackage: () => {
            const cloudstorage_store = useCloudstorageStore()
            let pck = cloudstorage_store.packagesByNameAndLevel({
                name: "resources",
                level: "parent_assembly_design"
            })?.[0]
            return pck
        },
        parentHeavyMetaPackage: () => {
            const cloudstorage_store = useCloudstorageStore()
            let pck = cloudstorage_store.packagesByNameAndLevel({
                name: "heavy_meta",
                level: "parent_assembly_design"
            })?.[0]
            return pck
        },
        allPackages: (state) => {
            const cloudstorage_store = useCloudstorageStore()
            return cloudstorage_store.packagesByNameAndLevel({
                name: null,
                level: state.level + "_design"
            })
        },
        getDrawings(state) {
            let files = this.resPackage?.files || []
            let drawings = files.filter(cf => cf.meta.section == state.level + "_drawing")
            let dwg_number_groups = _.groupBy(drawings, "meta.dwg_number")
            let filtered_dwgs = _.map(dwg_number_groups, group => _.maxBy(group, 'meta.version'))
            return filtered_dwgs
        },
        getIFC(state) {
            const selected_store = useSelectedStore()
            let files = []
            if (selected_store.isSubassd) files = this.parentResPackage?.files || []
            else files = this.resPackage?.files || []
            return files.find(cf => cf.meta.section == state.level + "_ifc")
        },
        module_layers() {
            const selected_store = useSelectedStore()
            let design = selected_store.annotatedRouteDesign
            let layers = [...(design?.meta?.assd_names || [])]
            layers.sort(nameSort)
            if (!layers) layers = ["main"]
            return layers
        },
        zones(state) {
            const selected_store = useSelectedStore()
            let layers
            let design = selected_store.annotatedRouteDesign
            if (selected_store.isSubassd) {
                layers = zones_from_viewer_bom(
                    state.bom,
                    design?.name
                )
                layers.unshift("PARENT")
            } else {
                layers = zones_from_viewer_bom(state.bom)
            }
            return layers
        },
    },
    actions: {
        setDesignsMode(dm) { this.designs_mode = dm },
        set3DMode() { this.pdf_mode = false },
        setPDFMode() { this.pdf_mode = true },
        setLevel(level) { this.level = level },
        setLeftPanelDWGNumber(idx) { this.left_panel_dwg_number = idx },
        setRightPanelMode(data) { this.right_panel_mode = data },
        setRightPanelCFUuid(uuid) { this.right_panel_cf_uuid = uuid },
        setSelectedPartsUuids(data) { this.selected_parts_ifc_ids = data.filter(v => !!v) },
        setSelectedPartProps(data) { this.selected_part_props = data },
        setSelectedZones(zones) { this.selected_zones = zones },
        setBomLoadingStatus(status) { this.bom_loading_status = status },
        setBom(bom) { this.bom = bom },

        loadBom() {
            const selected_store = useSelectedStore()
            this.setBomLoadingStatus(true)
            // Pick cloudfile
            let files = []
            if (selected_store.isSubassd) {
                files = this.parentHeavyMetaPackage?.files || []
            } else {
                files = this.heavyMetaPackage?.files || []
            }
            let cf = files.reduce(function (acc, obj) {
                if (obj.name == "viewer_bom" && obj.meta?.version > (acc?.meta?.version || -1)) {
                    return obj;
                } else {
                    return acc;
                }
            }, null);

            if (!cf) return

            // Download JSON
            console.log("[i] Downloading VIEWER BOM JSON.")
            fetch(cf.presigned_url)
                .then(r => r.json())
                .then(bom => this.setBom(bom))

            this.setBomLoadingStatus(false)
        }

    },
})
