<template>
    <v-select
        variant="outlined"
        density="compact"
        label="Language"
        :items="languages"
        v-model="language"
    ></v-select>
</template>

<script>
export default {
    props: ["modelValue", "allow_default"],
    data: () => ({
        languages: [
            { title: "Dutch (nl)", value: "nl" },
            { title: "English (en)", value: "en" },
            { title: "Spanish (es)", value: "es" },
            { title: "Polish (pl)", value: "pl" },
        ],
        language: "",
    }),
    watch: {
        modelValue: {
            handler: function (v) {
                this.language = v;
                console.log("immediate");
            },
            immediate: true,
        },
        language(lang) {
            this.$emit("update:modelValue", lang);
        },
    },
    mounted() {
        if (this.allow_default) {
            this.languages.unshift({ title: "Default", value: null });
        }
    },
};
</script>