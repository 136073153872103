// This is a map between company and project views.
// To be used when user switches mode, and select what
// view redirect to.

export const COMPANY_TO_PR_ROUTE = {
    "constructibles_manager": "constructibles",
    "company_settings": "project_settings",
    "company_reports": "reports",
    "production_overview": "project_overview",
}


export const PR_TO_COMPANY_ROUTE = {

    "viewer": "company_settings",
    "assd_viewer": "company_settings",
    "ass_viewer": "company_settings",
    "modd_viewer": "company_settings",
    "project_viewer": "company_settings",

    "panels": "company_settings",
    "panel_detail": "company_settings",
    "files": "company_settings",
    "designs": "company_settings",
    "design_detail": "company_settings",
    "design_parts": "company_settings",
    "project_overview": "production_overview",
    "issues": "company_settings",
    "issue_detail": "company_settings",
    "project_settings": "company_settings",
    "quantities": "company_settings",
    "reports": "company_reports",
    "constructibles": "constructibles_manager",
}

export const VIEWER_ROUTES = [
    "viewer",
    "assd_viewer",
    "ass_viewer",
    "modd_viewer",
    "mod_viewer",
    "project_viewer",
]

export const MISC_ROUTES = [
    "qr",
    "account",
]

export const NO_REDIRECT_ROUTES = [
    ...VIEWER_ROUTES,
    ...MISC_ROUTES,
    undefined,
]