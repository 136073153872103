
// This is the general purpose app-wide store.
import { defineStore } from 'pinia'
import bus from "/src/utils/event_bus"
import { useSelectedStore } from "./selected"
import { useSessionStore } from "./session"
import { useCompanyStore } from "./company"
import { useFactoriesStore } from "./factories"


export const useAppStore = defineStore('app', {
  state: () => ({
    app_mode: "production",
    active_loaders: [],  // Loading calls that are running
    bootup: true, // Only at startup is true

    route_name: "",
    connection_lost: false,
  }),
  getters: {
    loading: state => state.active_loaders.length != 0,
    loader_is_active: state => (loaders) => {
      if (Array.isArray(loaders)) {
        return loaders.some(loader => state.active_loaders.includes(loader));
      } else {
        return state.active_loaders.includes(loaders);
      }
    },
    companyWide: () => {
      const selected_store = useSelectedStore()
      return selected_store.pr_selection == "all"
    },
    m_bom: () => {
      const session_store = useSessionStore()
      return session_store.user_object?.auth?.company?.m_bom || false
    },
    m_machines: () => {
      const session_store = useSessionStore()
      return session_store.user_object?.auth?.company?.m_machines || false
    },
    m_prod_management: () => {
      const session_store = useSessionStore()
      return session_store.user_object?.auth?.company?.m_prod_management || false
    },
    t: () => function (key) {
      const translationsStore = useTranslationsStore()
      return translationsStore.translate(key)
    }
  },
  actions: {
    setViewerMode() { this.app_mode = "production" },
    setManagerMode() { this.app_mode = "manage" },
    setLogisticsMode() { this.app_mode = "logistics" },
    setMode(mode) { this.app_mode = mode },
    pushLoading(load) { this.active_loaders.push(load) },
    popLoading(load) { this.active_loaders = this.active_loaders.filter(l => l != load) },
    popLoadingIncludes(load) { this.active_loaders = this.active_loaders.filter(l => !l.includes(load)) },
    ready() { this.loading = false },
    endBootup() { this.bootup = false },
    setRouteName(name) { this.route_name = name },
    setConnectionLost(status) { this.connection_lost = status },



    async initialLoad() {
      const company_store = useCompanyStore()
      const factories_store = useFactoriesStore()
      return Promise.all([
        company_store.loadCompany(),
        factories_store.loadProductionLines(),
        company_store.loadIFCPSs(),
        company_store.loadBTLPSs(),
      ])
    },
    async refreshBaseUserData() {
      this.pushLoading("base_user_data")
      const company_store = useCompanyStore()
      const session_store = useSessionStore()
      return Promise.all([
        session_store.getUserObject(),
        company_store.loadProjects(),
        company_store.loadStandardLibraries(),
      ]).then(() => {
        if (this.bootup) {
          // ONLY ON BOOT
          let station = session_store.user_object.auth
            .selected_production_station;
          bus.emit("back_event", {
            event: "change_station",
            meta: { station },
          });
          this.endBootup()
        }
      }).finally(() => this.popLoading("base_user_data"))
    },
  }
})
