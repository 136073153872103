import { useAppStore } from "@/stores/app";
import { useSelectedStore } from "@/stores/selected";
import router from '/src/router'

// WATCHERS THAT LAUNCH ACTIONS FROM CLOUDSTORAGE STATE CHANGES

let last_app_mode = null

export const init_app_watchers = function () {
    const app_store = useAppStore()
    const selected_store = useSelectedStore()

    app_store.$subscribe((mutation, state) => {
        const app_mode = state.app_mode
        if (app_mode != last_app_mode) {
            // ROUTING
            if (app_mode == "logistics" && !["logistics", "company_logistics"].includes(router.currentRoute?.name)) {
                console.log("🍍👁️ logistics mode route watch:", router.currentRoute)
                if (selected_store.pr_selection == "all") {
                    router.push({ name: "company_logistics" })
                } else {
                    const project_uuid = selected_store.pr_selection
                    router.push({ params: { project_uuid }, name: "logistics" })
                }

            }
            last_app_mode = app_mode
        }
    })

}