// This is common logic to be inherited in components setup`()


// <span> {{ t("translated_thing") }} </span>


// import { usePitStoreBasics } from "@/stores/app_common";

// setup() {
//     return { ...usePitStoreBasics(), }
// },


// import { useFullPitStore } from "@/stores/app_common";

// setup() {
//     return { ...useFullPitStore(), }
// },

// import { useCompanyStore } from "@/stores/company";

// setup() {
//     return { company_store: useCompanyStore() }
// },


import { useIntegrationsStore } from "@/stores/integrations";
import { useLogisticsStore } from "@/stores/logistics";
import { useMailStore } from "@/stores/mail";
import { useQuantitiesStore } from "@/stores/quantities";
import { useRecordsStore } from "@/stores/records";
import { useReportsStore } from "@/stores/reports";
import { useAppStore } from "@/stores/app";
import { useCloudstorageStore } from "@/stores/cloudstorage";
import { useCompanyStore } from "@/stores/company";
import { useConstructiblesStore } from "@/stores/constructibles";
import { useFactoriesStore } from "@/stores/factories";
import { useSelectedLogisticsStore } from "@/stores/selected_logistics";
import { useSelectedStore } from "@/stores/selected";
import { useSessionStore } from "@/stores/session";
import { useTranslationsStore } from "@/stores/translations";
import { useViewerStore } from "@/stores/viewer";

export function usePitStoreBasics() {
    const app_store = useAppStore()
    const translations_store = useTranslationsStore()
    const t = translations_store.translate
    return {
        app_store,
        t,
    }
}


export function useFullPitStore() {
    const app_store = useAppStore()
    const cloudstorage_store = useCloudstorageStore()
    const company_store = useCompanyStore()
    const constructibles_store = useConstructiblesStore()
    const factories_store = useFactoriesStore()
    const integrations_store = useIntegrationsStore()
    const logistics_store = useLogisticsStore()
    const mail_store = useMailStore()
    const quantities_store = useQuantitiesStore()
    const records_store = useRecordsStore()
    const reports_store = useReportsStore()
    const selected_logistics_store = useSelectedLogisticsStore()
    const selected_store = useSelectedStore()
    const session_store = useSessionStore()
    const translations_store = useTranslationsStore()
    const viewer_store = useViewerStore()
    const t = translations_store.translate
    return {
        app_store,
        cloudstorage_store,
        company_store,
        constructibles_store,
        factories_store,
        integrations_store,
        logistics_store,
        mail_store,
        quantities_store,
        records_store,
        reports_store,
        selected_logistics_store,
        selected_store,
        session_store,
        translations_store,
        t,
        viewer_store,
    }
}

