import { backend_api_v2, paginated_factory_v2 } from '/src/utils/apiv2'
import bus from '/src/utils/event_bus'
import base from "./base"
import { defineStore } from 'pinia'

let LASTS = {}  // prevent old request fill data over new request.

export const useSelectedLogisticsStore = defineStore('selected_logistics', {
    state: () => ({
        selected_actuals: [],
        selected_branch: null,
        selected_size: null,
        selected_part_design: null,
        branches: [],
        sizes: [],
        parts: [],
        loading: []
    }),
    getters: {
        isLoading: state => tag => state.loading.includes(tag),
        branchAlike: state => {
            // TO BE UPDATED WITH IFCPS CUSTOM BRANCHES
            if (state.selected_branch == "timber") return "timber"
            else if (state.selected_branch == "plates") return "plates"
            else return "accesories"
        },
    },
    actions: {
        setSelectedActuals(actuals) { this.selected_actuals = actuals },
        setSelectedBranch(branch) { this.selected_branch = branch },
        setSelectedSize(size) { this.selected_size = size },
        setSelectedPartDesign(pd) { this.selected_part_design = pd },
        setBranches(branches) { this.branches = branches },
        setSizes(sizes) { this.sizes = sizes },
        setParts(parts) { this.parts = parts },
        replacePart: base.actions.updater("parts"),
        pushLoading(tag) { this.loading.push(tag) },
        popLoading(tag) { this.loading = this.loading.filter(t => t != tag) },

        // LOAD BRANCHES
        loadBranchesByActualUuids(payload) {
            this.pushLoading("branches");
            let url = `/constructibles/actuals/bulk_get_branches/`

            LASTS[url] = payload;

            return backend_api_v2.post(url, payload)
                .then(({ data }) => {
                    if (LASTS[url] == payload) {
                        this.setBranches(data.branches);
                        this.popLoading("branches");
                    } else {
                        console.log("[i] Rejecting loader commit. Not last call.")
                    }
                })
                .catch(() => bus.emit("notification", { text: `Can't load branches`, color: "error" }))
        },
        // LOAD SIZES
        loadSizesByActualUuidsAndBranch(payload) {
            this.pushLoading("sizes");
            let url;
            if (payload.branch === "timber") {
                url = `logistics/sizes/profiles/?branch=timber&actuals__in=${payload.actuals}&extended_serializer=true`;
            } else if (payload.branch === "plate") {
                url = `logistics/sizes/plates/?branch=plate&actuals__in=${payload.actuals}&extended_serializer=true`;
            }

            const keyword = `last_logistics_sizes`
            LASTS[keyword] = payload;

            return backend_api_v2.get(url, payload)
                .then(({ data }) => {
                    if (LASTS[keyword] == payload) {
                        this.setSizes(data.results);
                        this.popLoading("sizes");
                    } else {
                        console.log("[i] Rejecting loader commit. Not last call.")
                    }
                })
                .catch(() => bus.emit("notification", { text: `Can't load sizes`, color: "error" }))
        },
        // LOAD PARTS
        loadParts(payload) {
            this.pushLoading("parts");
            let url
            url = `constructibles/parts/?branch=${payload.branch}&size=${payload.size}&actual__in=${payload.actuals}&serialize_design=true`;

            const keyword = `last_logistics_parts`
            LASTS[keyword] = payload;

            return paginated_factory_v2(url)
                .then(({ data }) => {
                    if (LASTS[keyword] == payload) {
                        this.setParts(data);
                        this.popLoading("parts");
                    } else {
                        console.log("[i] Rejecting loader commit. Not last call.")
                    }
                })
                .catch(() => bus.emit("notification", { text: `Can't load parts`, color: "error" }))
        },
        // SET READY PARTS

        setReadyPartCount(payload) {
            this.pushLoading("ready_count");
            let url = `constructibles/parts/set_ready_count/?serialize_design=true`;

            return backend_api_v2.post(url, payload)
                .then(({ data }) => {
                    data.forEach(part => this.replacePart(part))
                    this.popLoading("ready_count");
                })
        },

    },
})

