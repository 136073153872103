import * as Sentry from "@sentry/vue";
import PRODUUZIT_FRONT_ENV from "/src/utils/defaults";



export function initSentry(app) {
    Sentry.init({
        app,
        dsn: "https://f908fea495d34f653e16635c563dfffb@o357522.ingest.us.sentry.io/4507843822419968",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        release: "woodwall-web@" + process.env.npm_package_version,
        environment: PRODUUZIT_FRONT_ENV.NODE_ENV,
        // Tracing
        tracesSampleRate: 0.1, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/produuz\.it\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

}
