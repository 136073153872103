import { useQuantitiesStore } from "@/stores/quantities";

// WATCHERS THAT LAUNCH ACTIONS FROM QUANTITIES STATE CHANGES


export const init_quantities_watchers = function () {
    const quantities_store = useQuantitiesStore()

    quantities_store.$subscribe((mutation, state) => {
        if (["hover_row", "hover_cell"].includes(mutation?.events?.key)) return
        console.log("🍍👁️ Empty watchers for quantities store.")
    })
}