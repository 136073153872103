<template>
    <div :class="{
        'd-flex flex-row-reverse': msg.contact.is_receiver,
        'd-flex flex-row': !msg.contact.is_receiver,
    }">
        <v-menu offset-y absolute>
            <template v-slot:activator="{ props }">
                <v-sheet :color="msg.contact.is_receiver ? 'accent' : ''" dark rounded small class="pa-2 mx-2 my-1"
                    v-bind="props" max-width="600">
                    <span class="text">{{ msg.text }}</span>
                    <v-row class="pa-2 mt-0">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-1" variant="outlined" size="small" @click.stop v-for="(button, i) in buttons" :key="i"
                            :to="button.route">
                            {{ button.text }}
                        </v-btn>
                        <v-btn class="mx-1" variant="outlined" size="small" v-if="'link' in metadata" :href="metadata.link"
                            target="_blank">
                            LINK
                        </v-btn>
                        <div>
                            <sub class="ml-2" style="font-size: 0.6rem">
                                <Timestamp :model-value="msg.created" />
                            </sub>
                            <DoubleCheckStatusIcon v-if="msg.contact.is_receiver" :msg="msg" />
                            <v-icon class="mr-2" size="small" end>
                                mdi-chevron-down
                            </v-icon>
                        </div>
                    </v-row>
                </v-sheet>
            </template>
            <v-list>
                <v-list-item @click="delete_message">
                    <v-list-item-title> Delete </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<style scoped>
.text {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    /* Fx>3, Opera>8, Safari>3*/
}
</style>
<script>
import Timestamp from "/src/components/renderers/Timestamp";
import DoubleCheckStatusIcon from "/src/components/renderers/DoubleCheckStatusIcon";
import { useMailStore } from "@/stores/mail";
import { usePitStoreBasics } from "@/stores/app_common";
import { mapState } from "pinia";
import { useSessionStore } from "@/stores/session";
export default {
    setup() { return { ...usePitStoreBasics(), mail_store: useMailStore() } },
    components: { DoubleCheckStatusIcon, Timestamp },
    props: ["msg"],
    computed: {
        metadata() {
            return this.msg.metadata || {};
        },
        ...mapState(useSessionStore, ["user_object"]),
        my_auth() {
            return this.user_object.auth;
        },
        buttons() {
            let buttons = [];
            if ("wall_unit" in this.metadata)
                buttons.push({
                    text: "panel",
                    route: "/units/" + this.metadata.wall_unit,
                });
            if ("assembly" in this.metadata)
                buttons.push({
                    text: "assembly",
                    route: "/viewer/assembly/" + this.metadata.assembly,
                });
            if ("module" in this.metadata)
                buttons.push({
                    text: "module",
                    route: "/viewer/module/" + this.metadata.module,
                });
            if ("project" in this.metadata && "design" in this.metadata)
                buttons.push({
                    text: "design",
                    route:
                        "/projects/" +
                        this.metadata.project +
                        "/designs/" +
                        this.metadata.design,
                });
            if ("project" in this.metadata)
                buttons.push({
                    text: "project",
                    route: "/projects/" + this.metadata.project + "/designs",
                });
            if (
                "issue" in this.metadata &&
                "issue_project" in this.metadata &&
                this.my_auth.role != "production_user"
            )
                buttons.push({
                    text: "issue",
                    route:
                        "/projects/" +
                        this.metadata.issue_project +
                        "/issues/" +
                        this.metadata.issue,
                });
            return buttons;
        },
        show_buttons() {
            return this.buttons.length != 0;
        },
    },
    methods: {
        delete_message() {
            console.log("delete", this.msg);
            this.mail_store.deleteMessage(this.msg);
        },
    },
};
</script>