import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
import { defineStore } from 'pinia'
import bus from '/src/utils/event_bus'
// import router from '/src/router'


export const useCompanyStore = defineStore('company', {
    state: () => ({
        company: { meta: {} },
        standard_libraries: [],
        projects: [],
        archived_projects: [],
        users: [],
        api_accounts: [],
        licenses: [],
        ifc_ps: [],
        btl_ps: [],
    }),
    getters: {
        // No getters
    },
    actions: {
        // My Company
        setCompany(data) { this.company = data },

        // Standard Libraries
        setStandardLibraries: base.actions.setter("standard_libraries"),
        createStandardLibrary: base.actions.creator("standard_libraries"),
        updateStandardLibrary: base.actions.updater("standard_libraries"),
        removeStandardLibrary: base.actions.remover("standard_libraries"),


        // Projects
        setProjects: base.actions.setter("projects"),
        setArchivedProjects: base.actions.setter("archived_projects"),
        createProject: base.actions.creator("projects"),
        updateProject: base.actions.updater("projects"),
        removeProject: base.actions.remover("projects"),

        // Users
        setUsers: base.actions.setter("users"),
        createUser: base.actions.creator("users"),
        updateUser: base.actions.updater("users"),
        removeUser: base.actions.remover("users"),

        // API Accounts
        setAPIAccounts: base.actions.setter("api_accounts"),
        createAPIAccount: base.actions.creator("api_accounts"),
        updateAPIAccount: base.actions.updater("api_accounts"),
        removeAPIAccount: base.actions.remover("api_accounts"),

        // Licenses
        setLicenses: base.actions.setter("licenses"),
        createLicense: base.actions.creator("licenses"),
        updateLicense: base.actions.updater("licenses"),
        removeLicense: base.actions.remover("licenses"),

        // IFCPS
        setIFCPSs: base.actions.setter("ifc_ps"),
        createIFCPS: base.actions.creator("ifc_ps"),
        updateIFCPS: base.actions.updater("ifc_ps"),
        removeIFCPS: base.actions.remover("ifc_ps"),

        // BTLPS
        setBTLPSs: base.actions.setter("btl_ps"),
        createBTLPS: base.actions.creator("btl_ps"),
        updateBTLPS: base.actions.updater("btl_ps"),
        removeBTLPS: base.actions.remover("btl_ps"),


        // My Company
        loadCompany() {
            return backend_api_v2.get('/my_company/')
                .then(({ data }) => {
                    this.setCompany(data)
                })
                .catch(e => console.log(`Cant load Compnay: ${e}`))
        },
        patchCompany(payload) {
            return backend_api_v2.put('/my_company/', payload)
                .then(({ data }) => {
                    this.setCompany(data)
                })
                .catch(e => console.log(`Cant patch company: ${e}`))
        },

        // Standard Libraries
        loadStandardLibraries: base.actions.loader("company/standard_libraries/", "StandardLibraries"),
        postStandardLibrary: base.actions.poster("company/standard_libraries/", "StandardLibrary"),
        putStandardLibrary: base.actions.putter("company/standard_libraries/", "StandardLibrary"),
        deleteStandardLibrary: base.actions.deleter("company/standard_libraries/", "StandardLibrary"),

        // Projects
        loadProjects: base.actions.loader("company/projects/", "Projects"),
        loadArchivedProjects: base.actions.loader("company/projects/?archived=true", "ArchivedProjects"),
        postProject: base.actions.poster("company/projects/", "Project"),
        putProject: base.actions.putter("company/projects/", "Project"),
        deleteProject: base.actions.deleter("company/projects/", "Project"),
        updateProjectResources(payload) {
            let project_uuid = payload.project.uuid
            return backend_api_v2.put('company/projects/' + project_uuid + '/patch_resources/', payload.data)
                .then(({ data }) => {
                    this.refreshProject(data)
                })
                .catch(e => console.log(`Cant update project resources: ${e}`))
        },

        // Users
        loadUsers: base.actions.loader("company/users/", "Users"),
        postUser: base.actions.poster("company/users/", "User"),
        putUser: base.actions.putter("company/users/", "User"),
        deleteUser: base.actions.deleter("company/users/", "User"),
        postUserPassword(payload) {
            return backend_api_v2.put('company/users/' + payload.id + "/change_password/", payload)
                .then(({ data }) =>
                    bus.emit("notification", {
                        timeout: 3000,
                        text: data.detail,
                        color: "success",
                    })
                )
                .catch(e => bus.emit("notification", {
                    timeout: 3000,
                    text: e,
                    color: "error",
                }))
        },

        // API Accounts
        loadAPIAccounts: base.actions.loader("company/api_accounts/", "APIAccounts"),
        postAPIAccount: base.actions.poster("company/api_accounts/", "APIAccount"),
        putAPIAccount: base.actions.putter("company/api_accounts/", "APIAccount"),
        deleteAPIAccount: base.actions.deleter("company/api_accounts/", "APIAccount"),

        // Licenses
        loadLicenses: base.actions.loader("company/licenses/", "Licenses"),
        postLicense: base.actions.poster("company/licenses/", "License"),
        putLicense: base.actions.putter("company/licenses/", "License"),
        deleteLicense: base.actions.deleter("company/licenses/", "License"),

        // IFCPS
        loadIFCPSs: base.actions.loader("company/ifc_ps/", "IFCPSs"),
        postIFCPS: base.actions.poster("company/ifc_ps/", "IFCPS"),
        putIFCPS: base.actions.putter("company/ifc_ps/", "IFCPS"),
        deleteIFCPS: base.actions.deleter("company/ifc_ps/", "IFCPS"),

        // BTLPS
        loadBTLPSs: base.actions.loader("company/btl_ps/", "BTLPSs"),
        postBTLPS: base.actions.poster("company/btl_ps/", "BTLPS"),
        putBTLPS: base.actions.putter("company/btl_ps/", "BTLPS"),
        deleteBTLPS: base.actions.deleter("company/btl_ps/", "BTLPS"),
    },
})
