<template>
    <v-dialog v-model="dialog" max-width="800">
        <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" :disabled="!can_select_station">
                <template v-slot:prepend>
                    <v-icon> mdi-toolbox </v-icon>
                </template>
                <v-list-item-title>
                    {{ t("change_station") }}
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card :loading="loading ? 'accent' : false">
            <v-card-title class="text-h5">
                {{ t("change_station") }}
            </v-card-title>
            <v-card-subtitle>
                {{ t("change_station_help_text") }}
            </v-card-subtitle>
            <v-card-text v-if="production_lines.length == 0 && loading">
                <v-skeleton-loader type="list-item-avatar-three-line@2">
                </v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
                <h3 v-if="i_can_see_other_lines">
                    {{ t("no_production_line") }}
                </h3>
                <div v-if="i_can_see_other_lines" class="d-flex ma-3">
                    <v-card border class="ma-1" :color="selected_station == null ? 'accent' : ''" width="220"
                        height="120" @click="clean_station">
                        <v-card-title>
                            <v-icon start> mdi-close </v-icon>
                            {{ t("no_station") }}
                        </v-card-title>
                    </v-card>
                </div>
                <div v-for="pl in production_lines" :key="pl.uuid">
                    <h3>{{ pl.name }}</h3>
                    <div class=" ma-3" style="overflow-x: auto; white-space: nowrap;">
                        <v-card v-for="sta in pl.stations" :key="sta.uuid" @click="change_station(sta)" border
                            class="ma-1 d-inline-block" :color="selected_station == sta.uuid ? 'accent' : ''"
                            width="180" height="120">
                            <v-card-title class="text-truncate">
                                <v-icon start> mdi-toolbox </v-icon>
                                <v-tooltip location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">
                                            {{ sta.name }}
                                        </span>
                                    </template>
                                    {{ sta.name }}
                                </v-tooltip>
                            </v-card-title>
                            <v-card-text>
                                <div v-if="sta.zones.length != 0" class="text-truncate">
                                    <v-icon size="small">
                                        mdi-layers-triple-outline
                                    </v-icon>
                                    Zones: {{ sta.zones.join(", ") }}
                                </div>
                                <div v-if="sta.machine_setup" class="text-truncate">
                                    <v-icon size="small">
                                        mdi-robot-industrial
                                    </v-icon>
                                    {{ getMachineName(sta.machine_setup) }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" color="secondary">
                    {{ t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";
import { useFactoriesStore } from "@/stores/factories";
import { useSessionStore } from "@/stores/session";
import { useTranslationsStore } from "@/stores/translations";
import { nameSort } from "/src/utils/basics.js";

export default {
    setup() {
        const factories_store = useFactoriesStore()
        const session_store = useSessionStore()
        const translations_store = useTranslationsStore()
        const t = translations_store.translate
        return {
            factories_store,
            session_store,
            t,
        }
    },
    data: () => ({
        loading: false,
        dialog: false,
    }),
    computed: {
        can_select_station() {
            return this.session_store.user_object?.auth
                ?.can_select_station;
        },
        production_lines() {
            let pls = this.factories_store.production_lines;
            // Filter visibles
            pls = pls.filter(
                (pl) => pl.uuid == this.my_line || this.i_can_see_other_lines
            );
            // Order stations
            pls = pls.map((pl) => ({
                ...pl,
                stations: _.orderBy(pl.stations, "order"),
            }));

            return pls.sort((a, b) => nameSort(a.name, b.name))
        },
        my_line() {
            return this.session_store.my_line_uuid
        },
        selected_station() {
            return this.session_store.user_object.auth
                .selected_production_station;
        },
        i_can_see_other_lines() {
            return (
                this.session_store.user_object?.auth
                    ?.company_admin ||
                this.session_store.user_object?.auth
                    ?.company_manager
            );
        },
    },
    methods: {
        getMachineName(machine_setup) {
            const mach = this.factories_store.machines.find(
                (m) => m.uuid == machine_setup
            );
            return mach?.name || "machine";
        },
        change_station(sta) {
            this.loading = true;
            this.$bus.emit("back_event", {
                event: "change_station",
                meta: { station: sta.uuid },
            });
            this.session_store.postUserSelectedStation(sta.uuid)
                .then(() => {
                    this.dialog = false;
                    this.loading = false;
                });
        },
        clean_station() {
            this.$bus.emit("back_event", {
                event: "change_station",
                meta: { station: "" },
            });
            this.loading = true;
            this.session_store.postUserSelectedStation("")
                .then(() => {
                    this.dialog = false;
                    this.loading = false;
                });
        },
    },
    watch: {
        dialog(d) {
            if (d) {
                this.loading = true;
                this.factories_store.loadProductionLines()
                    .then(() => (this.loading = false));
            } else {
                this.$emit("close");
            }
        },
    },
};
</script>