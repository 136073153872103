<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ props }">
            <v-list-item v-bind="props">
                <template v-slot:prepend>
                    <v-icon> mdi-theme-light-dark </v-icon>
                </template>
                <v-list-item-title>
                    {{ t("theme") }}
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card>
            <v-card-title>
                {{ t("theme_title") }}
            </v-card-title>

            <v-card-text> {{ t("theme_text") }} </v-card-text> <v-card-text>
                <v-select variant="outlined" density="compact" v-model="theme" :items="options" :label="t('theme')">
                </v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="dialog = false">
                    {{ t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { useTranslationsStore } from "@/stores/translations";

export default {
    setup() {
        const t = useTranslationsStore().translate
        return { t }
    },
    data: () => ({
        loading: false,
        dialog: false,
        theme: localStorage.getItem("theme"),
    }),
    computed: {
        options() {
            return [
                { value: "auto", title: this.t("auto_dark") },
                { value: "light", title: this.t("light_mode") },
                { value: "dark", title: this.t("dark_mode") },
            ];
        },
    },
    watch: {
        theme(theme) {
            localStorage.setItem("theme", theme);
            if (theme == "auto") {
                const web_dark = window.matchMedia(
                    "(prefers-color-scheme: dark)"
                ).matches;
                this.$vuetify.theme.global.name = web_dark ? "dark" : "light"
            } else {
                this.$vuetify.theme.global.name = theme
            }
        },
    },
};
</script>