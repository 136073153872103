<template>
    <v-card variant="flat" class="ma-3 border-thin">
        <v-btn-toggle mandatory v-model="mode" border="xs" variant="text" color="accent">
            <v-btn color="accent" width="125" height="50" value="manage">
                <div class="dl-flex flex-column">
                    <v-icon class="ml-2 mr-1 mb-1">
                        mdi-laptop
                    </v-icon>
                    <br />
                    {{ t("management_mode_short") }}
                </div>
            </v-btn>
            <v-btn color="accent" width="125" height="50" value="logistics" :disabled="disable_viewer">
                <div class="dl-flex flex-column">
                    <v-icon class="ml-2 mr-1 mb-1">
                        mdi-forklift
                    </v-icon>
                    <br />
                    {{ t("logistics_mode_short") }}
                </div>
            </v-btn>
            <v-btn color="accent" width="125" height="50" value="production" :disabled="disable_viewer">
                <div class="dl-flex flex-column">
                    <v-icon class="ml-2 mr-1 mb-1">
                        mdi-floor-plan
                    </v-icon>
                    <br />
                    {{ t("production_mode_short") }}
                </div>
            </v-btn>
        </v-btn-toggle>
    </v-card>
</template>


<script>
import { useAppStore } from "@/stores/app";
import { useSelectedStore } from "@/stores/selected";
import { useSessionStore } from "@/stores/session";
import { useTranslationsStore } from "@/stores/translations";

export default {
    setup() {
        const app_store = useAppStore()
        const selected_store = useSelectedStore()
        const session_store = useSessionStore()
        const translations_store = useTranslationsStore()
        const t = translations_store.translate
        return {
            app_store,
            selected_store,
            session_store,
            t,
        }
    },
    components: {},
    data: () => ({ mode: "production" }),
    computed: {
        store_mode() {
            return this.app_store.app_mode;
        },
        stdlib_mode() {
            return this.selected_store.stdlib_mode
        },
        disable_viewer() {
            if (this.stdlib_mode) return true
            if (this.app_store.companyWide && !this.session_store.work_by_date) return true
            return false
        },
    },
    watch: {
        store_mode: {
            handler: function (sm) {
                this.mode = sm;
            },
            immediate: true,
        },
        mode(m) {
            this.app_store.setMode(m);
        },
    },
    methods: {},
};
</script>
