/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { pl, en, es, nl } from 'vuetify/locale'

// Composables
import { createVuetify } from 'vuetify'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { VTreeview } from 'vuetify/labs/VTreeview'


function determine_theme() {
  const web_dark = window.matchMedia('(prefers-color-scheme: dark)').matches
  let theme = localStorage.getItem("theme");
  if (!theme || theme == "auto") theme = web_dark ? "dark" : "light"
  return theme
}



// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    VTimePicker,
    VTreeview,
    VDateInput,
  },
  theme: {
    defaultTheme: determine_theme(),
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#1976D2',
          secondary: '#242021',
          accent: '#ea590c',
          error: '#c90202', // default was '#FF5252'
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#ea590c',
          worange: "#ea590c",  // accent, warning produuz.it
          wblack: "#242021",  // secondary produuz.it
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#1976D2',
          secondary: "#707070",
          accent: '#ea590c',
          error: '#c90202', // default was '#FF5252'
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#ea590c',
          worange: "#ea590c",  // accent, warning produuz.it
          wblack: "#242021",  // secondary produuz.it
        }
      }
    },
  },
  locale: {
    locale: 'en',
    messages: { en, nl, es, pl },
  },
})
