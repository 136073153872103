import defaults from '/src/utils/defaults';
import { useSessionStore } from "/src/stores/session"
import { useAppStore } from "/src/stores/app"
import axios from 'axios'
import bus from '/src/utils/event_bus'


const backend_api_v2 = axios.create({
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: defaults.ROOT_API,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
});
backend_api_v2.interceptors.request.use(config => {
    let session_store
    try {
        session_store = useSessionStore()
    } catch {
        console.log("[🍍] Pinia not initialized. Sending NO_AUTH request.")
        session_store = { authenticated: false }
    }
    if (session_store.authenticated) {
        config['headers']['Authorization'] = 'Bearer ' + session_store.access_token
    } else {
        config['headers']['Authorization'] = 'NO_AUTH'
    }
    return config
})
backend_api_v2.interceptors.response.use(
    response => {
        const app_store = useAppStore()
        if (app_store.connection_lost) {
            app_store.setConnectionLost(false)
            bus.emit('notification', { timeout: 5000, text: "Connection recovered", color: "success" })
            bus.emit("back_event", { event: "connection_recovered", meta: {} })
        }
        return response;
    },
    error => {
        if (!error.response) {
            const app_store = useAppStore()
            if (!app_store.connection_lost) {
                app_store.setConnectionLost(true)
                bus.emit('notification', { timeout: 5000, text: "Error - Coneection lost", color: "error" })
                bus.emit("back_event", { event: "connection_lost", meta: {} })
            }
            return Promise.reject(error);
        }
        else if (error?.response?.status == 403 && error.response?.data?.code == "token_not_valid") {
            let session_store = useSessionStore()
            session_store.refresh()
        }
        else if (error?.response?.status == 403 && error?.response?.data?.detail == "You do not have permission to perform this action.") {
            console.log("[!] Permission error. Check the Network tab in the browser.")
            return Promise.reject(error)
        }
        else if (error?.response?.status == 403) {
            bus.emit("events/session/logout")
        }
        else if (error?.response?.status == 401 && error.response?.data?.code == "token_not_valid") {
            bus.emit("events/session/logout")
        }
        return Promise.reject(error)
    }
)


// returns a complete promise
function paginated_factory_v2(url) {
    function paginatedFetcher(next, obj_list = []) {
        return backend_api_v2.get(next ? next : url)
            .then(({ data }) => {
                obj_list.push(...data.results)
                if (!data.next) return obj_list
                return paginatedFetcher(data.next, obj_list)
            })
    }
    return paginatedFetcher().then(data => ({ data }))
}


export {
    backend_api_v2,
    paginated_factory_v2
};


// LOGOUT FROM SESSION !!!!!!
axios.get(defaults.ROOT_API + 'auth/logout/')
    .then(console.log("Cleaned session"))