<template>
    <v-dialog width="1200" v-model="dialog" :scrim="false" transition="dialog-top-transition">
        <template v-slot:activator="{ props }">
            <v-list-item v-if="listitem != undefined" v-bind="props">
                <template v-slot:prepend>
                    <v-badge color="green" :model-value="!!unread_count" :content="unread_count">
                        <v-icon> mdi-message-text </v-icon>
                    </v-badge>
                </template>
                <v-list-item-title>
                    chat
                </v-list-item-title>
            </v-list-item>
            <v-badge v-else v-bind="props" color="green" :model-value="!!unread_count" :content="unread_count">
                <v-btn icon="mdi-message-text" variant="text">
                </v-btn>
            </v-badge>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card v-if="isActive">
                <v-toolbar bordered density="compact" color="accent">
                    <v-toolbar-title> {{ t("chat") }} </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn variant="text" icon="mdi-close" dark @click="isActive.value = false">
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <ChatBox />
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import { useMailStore } from "@/stores/mail";
import { useTranslationsStore } from "@/stores/translations";
import ChatBox from "/src/components/chat/ChatBox";
export default {

    setup() {
        const mail_store = useMailStore()
        const translations_store = useTranslationsStore()

        const t = translations_store.translate

        return {
            mail_store,
            t,
        }
    },
    components: { ChatBox },
    props: ["listitem"],
    data: () => ({
        interval_count: null,
        interval_LastMessages: null,
        dialog: false,
    }),
    computed: {
        unread_count() {
            return this.mail_store.unread_count;
        },
    },
    watch: {
        dialog(newVal) {
            if (newVal) {
                this.mail_store.loadLastMessages();
                this.interval_LastMessages = setInterval(() => this.mail_store.loadLastMessages(), 15000);
            } else {
                console.log("killing loadmessages timeout", clearInterval(this.interval_LastMessages));
            }
        },
    },
    methods: {
        loadUnreadCount() {
            this.mail_store.loadUnreadCount()
        },
    },
    mounted() {
        this.loadUnreadCount();
        this.interval_count = setInterval(this.loadUnreadCount, 15000);
    },
    beforeUnmount() {
        console.log("killing count timeout", clearInterval(this.interval_count));
    },
};
</script>