<template>
    <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ props }">
            <v-list-item v-if="listitem != undefined" v-bind="props">
                <template v-slot:prepend>
                    <v-icon v-if="loading" color="accent"> mdi-loading mdi-spin </v-icon>
                    <v-icon v-else> mdi-account-hard-hat </v-icon>
                </template>
                <v-list-item-title>
                    user
                </v-list-item-title>
            </v-list-item>
            <v-card v-else variant="flat" class="border-thin" width="180" v-bind="props" :loading="loading ? 'accent' : false"
                loader-height="2">
                <div class="d-flex">
                    <v-icon color="grey" class="ml-2 mr-1 my-3">
                        mdi-account-hard-hat
                    </v-icon>
                    <div class="ml-1 text-caption text-grey d-flex flex-column">
                        <span class="mt-1 d-inline-block text-truncate" style="max-width: 135px">
                            {{ username }}
                        </span>
                        <span class="mb-1 d-inline-block text-truncate" style="max-width: 135px">
                            {{ production_station_name }}
                            [{{ production_line_name }}]
                        </span>
                    </div>
                </div>
            </v-card>
        </template>
        <v-list density="compact">
            <ButtonStaffChangeCompany v-if="i_am_staff" />
            <ButtonStation @close="closeMenu" />
            <v-list-item v-if="management_permission || admin_permission" to="/company/settings">
                <template v-slot:prepend>
                    <v-icon> mdi-factory </v-icon>
                </template>
                <v-list-item-title> {{ t("company") }} </v-list-item-title>
            </v-list-item>
            <v-list-item @click="refreshBaseUserData">
                <template v-slot:prepend>
                    <v-icon> mdi-sync </v-icon>
                </template>
                <v-list-item-title>
                    {{ t("resync") }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="is_android" @click="hardRefresh">
                <template v-slot:prepend>
                    <v-icon> mdi-power-plug-outline </v-icon>
                </template>
                <v-list-item-title>
                    {{ t("hard_resync") }}
                </v-list-item-title>
            </v-list-item>
            <ButtonForceDark />
            <v-list-item to="/account">
                <template v-slot:prepend>
                    <v-icon> mdi-account </v-icon>
                </template>
                <v-list-item-title>
                    {{ t("account") }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
                <template v-slot:prepend>
                    <v-icon> mdi-logout </v-icon>
                </template>
                <v-list-item-title>{{
        t("logout")
    }}</v-list-item-title>
            </v-list-item>
            <v-list-subheader>
                <v-chip v-if="connetion_lost" size="small" color="error" class="ma-1">offline</v-chip>
                <v-chip v-else size="small" color="success" class="ma-1">online</v-chip>
                <v-chip color="" size="small" class="ma-1">{{ build_version }}</v-chip>
            </v-list-subheader>
        </v-list>
    </v-menu>
</template>


<script>
import ButtonStation from "/src/components/left_menu/ButtonStation";
import ButtonForceDark from "/src/components/left_menu/ButtonForceDark";
import PRODUUZIT_FRONT_ENV from "/src/utils/defaults";
import ButtonStaffChangeCompany from "./ButtonStaffChangeCompany.vue";
import { useAppStore } from "@/stores/app";
import { useSessionStore } from "@/stores/session";
import { useTranslationsStore } from "@/stores/translations";

export default {
    setup() {
        const app_store = useAppStore()
        const session_store = useSessionStore()
        const translations_store = useTranslationsStore()
        const t = translations_store.translate
        return {
            app_store,
            session_store,
            t,
        }
    },
    components: { ButtonStation, ButtonForceDark, ButtonStaffChangeCompany },
    props: ["listitem"],
    data: () => ({ loading: false, menu: false }),
    computed: {
        connetion_lost() {
            return this.app_store.connection_lost;
        },
        viewer_mode() {
            return this.app_store.app_mode == "production";
        },
        i_am_staff() {
            return this.session_store.user_object?.auth?.pit_staff;
        },
        is_android() {
            return PRODUUZIT_FRONT_ENV.IS_ANDROID;
        },
        build_version() {
            return PRODUUZIT_FRONT_ENV.BUILD_VERSION;
        },
        production_line_name() {
            let pl_name =
                this.session_store.user_object.auth.selected_production_line_name;
            return pl_name || this.t("no_station");
        },
        production_station_name() {
            return this.session_store.user_object.auth.selected_production_station_name;
        },
        username() {
            return this.session_store.user_object.username;
        },
        management_permission() {
            return this.session_store.user_object.auth
                ?.company_manager;
        },
        admin_permission() {
            return this.session_store.user_object.auth?.company_admin;
        },
    },
    methods: {
        logout() {
            this.session_store.cleanSession()
            this.$router
                .push("/login")
                .catch(() =>
                    console.log(
                        "[!] Can't go to login. Maybe already in login page."
                    )
                );
        },
        switch_to_manage() {
            this.app_store.setManagerMode()
        },
        switch_to_viewer() {
            this.app_store.setViewerMode()
        },
        refreshBaseUserData() {
            this.loading = true;
            let proms = [
                this.app_store.initialLoad(),
                this.app_store.refreshBaseUserData(false)
            ]
            Promise.all(proms).finally(() => (this.loading = false));
        },
        hardRefresh() {
            location.reload();
        },
        closeMenu() {
            this.menu = false;
        },
    },
};
</script>