<template>
    <div class="mx-4 mb-2 mt-0">
        <v-btn-toggle mandatory :model-value="designs_mode" @update:model-value="dm($event)">
            <v-btn variant="outlined" color="accent" :value="false" width="50%">
                <span class="text-truncate" style="width: 160px">
                    {{ t("production") }}
                </span>
            </v-btn>
            <v-btn variant="outlined" color="accent" :value="true" width="50%">
                <span class="text-truncate" style="width: 160px">
                    {{ t("design") }}
                </span>
            </v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
import { usePitStoreBasics } from "@/stores/app_common";
import { useViewerStore } from "@/stores/viewer";
export default {
    setup() {
        const store_basics = usePitStoreBasics()
        const viewer_store = useViewerStore()
        return { ...store_basics, viewer_store }
    },
    computed: {
        designs_mode() {
            return this.viewer_store.designs_mode;
        },
    },
    methods: {
        dm(designs_mode) {
            this.viewer_store.setDesignsMode(designs_mode)
        }
    }
}
</script>