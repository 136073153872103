import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
// import bus from '/src/utils/event_bus'
// import router from '/src/router'
import { defineStore } from 'pinia'

export const useReportsStore = defineStore('reports', {
    state: () => ({
        reports: [],
    }),
    getters: {
        // No getters
    },
    actions: {
        // Reports
        setReports: base.actions.setter("reports"),
        replaceReport: base.actions.updater("reports"),
        createReport: base.actions.creator("reports"),
        updateReport: base.actions.updater("reports"),
        removeReport: base.actions.remover("reports"),

        // Reports
        loadReports: base.actions.loader_pr("reports/reports/", "Reports"),
        postReport: base.actions.poster("reports/reports/", "Report"),
        putReport: base.actions.putter("reports/reports/", "Report"),
        deleteReport: base.actions.deleter("reports/reports/", "Report"),
        refreshReport: base.actions.refresher("reports/reports/", "Report"),

        putReportNoJob(payload) {
            const url = "reports/reports/";
            const finalUrl = `${url}${payload?.uuid}/?no_job=true`;

            return backend_api_v2.put(finalUrl, payload)
                .then(({ data }) => {
                    this.updateReport(data)
                    return { data }
                });
        }
    },
})
