import base from "./base"
import { defineStore } from 'pinia'

export const useIntegrationsStore = defineStore('integrations', {
    state: () => ({
        webhooks: [],
    }),
    getters: {
        // No getters
    },
    actions: {
        // Webhooks
        setWebhooks: base.actions.setter("webhooks"),
        createWebhook: base.actions.creator("webhooks"),
        updateWebhook: base.actions.updater("webhooks"),
        removeWebhook: base.actions.remover("webhooks"),

        // Webhooks
        loadWebhooks: base.actions.loader("integrations/webhooks/", "Webhooks"),
        postWebhook: base.actions.poster("integrations/webhooks/", "Webhook"),
        putWebhook: base.actions.putter("integrations/webhooks/", "Webhook"),
        deleteWebhook: base.actions.deleter("integrations/webhooks/", "Webhook"),
    },
})
