import { paginated_factory_v2, backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
import { defineStore } from 'pinia'

let LASTS = {}

function loadPackagesFactory(keyword, api_query_level) {
    if (!api_query_level) {
        api_query_level = keyword;
    }

    const loadPackages = function (uuid) {

        // Register last call (ensure last called fills)
        LASTS[keyword] = uuid

        // Fill it
        return paginated_factory_v2(`/cloudstorage/packages/?${api_query_level}=${uuid}`)
            .then(({ data }) => {
                // Accept last
                if (LASTS[keyword] == uuid)
                    this[`set_${keyword}_packages`](data)
                // Reject prebounce
                else console.log("[i] Rejecting loadPackages commit. Not last call.")
                return data
            })
            .catch(e => console.log(`Cant load packages: ${e}`))
    }
    return loadPackages
}


function cleanDuplicatesInPackage(uuid) {
    // Warning, this doesn't update store. Needs proper update.
    return backend_api_v2.post(`/cloudstorage/packages/${uuid}/clean_duplicates/`)
        .then(({ data }) => {
            return { data }
        })
        .catch(e => console.log(`Cant clean package: ${e}`))
}


function deleteCloudFileByPckAndName(payload) {
    // Warning, this doesn't update store. Needs proper update.
    return backend_api_v2.post(`/cloudstorage/packages/${payload.pck}/clean_files_by_name/`, { name: payload.name })
        .then(({ data }) => {
            return { data }
        })
        .catch(e => console.log(`Cleant package: ${e}`))
}



export const useCloudstorageStore = defineStore('cloudstorage', {
    state: () => ({
        company_packages: [],
        project_packages: [],
        module_packages: [],
        assembly_packages: [],
        module_design_packages: [],
        assembly_design_packages: [],
        parent_assembly_design_packages: [],
    }),
    getters: {
        packagesByNameAndLevel: state => ({ name, level }) => {
            if (name == null) return state[`${level}_packages`]
            else if (Array.isArray(name))
                return state[`${level}_packages`].filter(pckg => name.includes(pckg.name))
            else
                return state[`${level}_packages`].filter(pckg => pckg.name == name)
        },
        packageByUuid: state => uuid => state.project_packages.find(p => p.uuid == uuid)
            || state.module_packages.find(p => p.uuid == uuid)
            || state.assembly_packages.find(p => p.uuid == uuid)
            || state.module_design_packages.find(p => p.uuid == uuid)
            || state.assembly_design_packages.find(p => p.uuid == uuid),
        resourcesPackage: state => state.project_packages.find((pck) => pck.name == "resources"),


        // Full project last revisions
        hasFullProjectResources: (state) => {
            let files = state.resourcesPackage?.files || []
            let pr_files = files
                .filter(cf => ["project_ifc", "module_drawing"]
                    .includes(cf.meta.section))
            return pr_files.length != 0
        },
    },
    actions: {
        set_company_packages(data) { this.company_packages = data },
        set_project_packages(data) { this.project_packages = data },
        set_module_packages(data) { this.module_packages = data },
        set_assembly_packages(data) { this.assembly_packages = data },
        set_module_design_packages(data) { this.module_design_packages = data },
        set_assembly_design_packages(data) { this.assembly_design_packages = data },
        set_parent_assembly_design_packages(data) { this.parent_assembly_design_packages = data },

        removeCloudFile(uuid) {
            const store_keys = [
                "company_packages",
                "project_packages",
                "module_packages",
                "assembly_packages",
                "module_design_packages",
                "assembly_design_packages",
                "parent_assembly_design_packages",
            ]
            store_keys.forEach((k) => {
                if ("files" in this[k]) {
                    this[k].files = this[k].files.filter(cf => cf.uuid != uuid)
                }
            })
        },

        loadCompanyPackages: loadPackagesFactory("company"),
        loadProjectPackages: loadPackagesFactory("project"),
        loadModulePackages: loadPackagesFactory("module"),
        loadAssemblyPackages: loadPackagesFactory("assembly"),
        loadModuleDesignPackages: loadPackagesFactory("module_design"),
        loadAssemblyDesignPackages: loadPackagesFactory("assembly_design"),
        loadParentAssemblyDesignPackages: loadPackagesFactory("parent_assembly_design", "assembly_design"),

        deleteCloudFile: base.actions.deleter("cloudstorage/files/", "CloudFile"),
        deleteCloudFileByPckAndName,
        cleanDuplicatesInPackage,
    },
})
