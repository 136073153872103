<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ props }">
            <div class="d-flex justify-center align-center">
                <v-btn variant="outlined text" :loading="loading" :disabled="loading" v-bind="props" color="accent"
                    class="mr-2">
                    <v-icon start>mdi-sticker-check-outline</v-icon>
                    {{ t("kitted_parts") }}
                </v-btn>
            </div>
        </template>
        <v-card>
            <v-card-title class="text-h5">
                {{ t("set_constructible_as_kitted_parts") }}
            </v-card-title>
            <v-card-text>
                {{ t("set_constructible_as_kitted_parts_text") }}:
                <ul>
                    <li v-for="(act, index) in actuals" :key="index">
                        {{ act }}
                    </li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="set_as_ready()" color="accent" :loading="loading" :disabled="loading">
                    {{ t("kitted_parts") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { usePitStoreBasics } from "@/stores/app_common";
import { backend_api_v2 } from "/src/utils/apiv2";
import { mapActions } from "pinia";
import { useConstructiblesStore } from "@/stores/constructibles";

export default {
    setup() { return { ...usePitStoreBasics(), } },
    props: ["actuals"],
    data: () => ({
        loading: false,
        dialog: false,
    }),
    computed: {},
    methods: {
        ...mapActions(useConstructiblesStore, ["updateAssembly"]),
        set_as_ready() {
            console.log("[i] Marking parts as ready.");
            this.loading = true;
            let url = `/constructibles/assemblies/mark_kitted/`;

            backend_api_v2
                .post(url, this.actuals)
                .then((r) => {
                    if (r.status == 200) {
                        console.log(r.data);
                        r.data.forEach(ass => this.updateAssembly(ass))
                        console.log("[i] Parts marked as ready.");
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.dialog = false;
                });
        },
    }
}
</script>