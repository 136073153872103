import base from "./base"
import { defineStore } from 'pinia'

export const useFactoriesStore = defineStore('factories', {
    state: () => ({
        production_lines: [],
        machines: [],
    }),
    getters: {
        // No getters
    },
    actions: {
        // Production Lines
        setProductionLines: base.actions.setter("production_lines"),
        createProductionLine: base.actions.creator("production_lines"),
        updateProductionLine: base.actions.updater("production_lines"),
        removeProductionLine: base.actions.remover("production_lines"),

        loadProductionLines: base.actions.loader("factories/production_lines/", "ProductionLines"),
        postProductionLine: base.actions.poster("factories/production_lines/", "ProductionLine"),
        putProductionLine: base.actions.putter("factories/production_lines/", "ProductionLine"),
        deleteProductionLine: base.actions.deleter("factories/production_lines/", "ProductionLine"),


        // Machines
        setMachines: base.actions.setter("machines"),
        createMachine: base.actions.creator("machines"),
        updateMachine: base.actions.updater("machines"),
        removeMachine: base.actions.remover("machines"),

        loadMachines: base.actions.loader("factories/machines/", "Machines"),
        postMachine: base.actions.poster("factories/machines/", "Machine"),
        putMachine: base.actions.putter("factories/machines/", "Machine"),
        deleteMachine: base.actions.deleter("factories/machines/", "Machine"),
    },
})
