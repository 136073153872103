<template>
    <v-app class="app">
        <div class="nav-icon-collapsed">
            <v-btn v-if="show_drawer" variant="text" :icon="drawer ? 'mdi-chevron-left' : 'mdi-chevron-right'"
                @click.stop="switch_drawer">
            </v-btn>
        </div>
        <v-navigation-drawer permanent v-if="show_drawer" :rail="!drawer" touchless app width="400">
            <LeftMenu :rail="!drawer" />
        </v-navigation-drawer>
        <v-main>
            <router-view></router-view>
            <Notifications />
        </v-main>
        <v-overlay :model-value="loading_overlay" class="d-flex align-center justify-center">
            <v-img width="80" src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_icon.png">
            </v-img>
            <span class="text-accent text-h6"> Loading ... </span>
        </v-overlay>

    </v-app>
</template>

<style lang="scss">
// set left padding for <ul> and <li> elements
// in all texts in the app
ul {
    padding-left: 20px;
}


.app {
    height: 100hv;
    touch-action: none;
}

.nav-icon-collapsed {
    position: fixed;
    top: 15px;
    left: 5px;
    z-index: 10000;
}

::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #ff770054;
    /* color of the scroll thumb */
    border-radius: 8px;
    /* roundness of the scroll thumb */
}
</style>

<script>
import LeftMenu from "./components/left_menu/LeftMenu.vue";
import { useAppStore } from "./stores/app";
import { useSessionStore } from "./stores/session";
import Notifications from "/src/components/Notifications";


export default {
    components: {
        LeftMenu,
        Notifications,
    },
    data: () => ({
        drawer: true,
    }),
    computed: {
        loading_overlay() {
            const app_store = useAppStore();
            return app_store.loader_is_active("base_user_data") || app_store.loader_is_active("route");
        },
        show_drawer() {
            return !["login"].includes(this.$route.name);
        },
        company_color() {
            return useSessionStore().user_object?.auth?.company
                ?.meta?.company_color;
        },
        iam_prod_worker() {
            return useSessionStore().iam_prod_worker;
        },
    },
    watch: {
        company_color: {
            handler: function (c, oc) {
                try {
                    if (c && c != oc) {
                        this.$vuetify.theme.themes.light.accent = c;
                        this.$vuetify.theme.themes.dark.accent = c;
                        document.body.style["::-webkit-scrollbar-thumb"][
                            "background-color"
                        ] = c;
                    }
                } catch {
                    console.log("[!] Skipping setup of accent color.");
                }
            },
            immediate: true,
        },
        iam_prod_worker: {
            handler(iam) {
                this.drawer = !iam
            },
            immediate: true
        },
    },
    methods: {
        switch_drawer() {
            this.drawer = !this.drawer;
        },
    },
    mounted() {
        useSessionStore().refresh()
        const app_store = useAppStore()

        // First load
        app_store.initialLoad() // only initial
        app_store.refreshBaseUserData(false)

        // 2 minutes loads
        setInterval(
            () => app_store.refreshBaseUserData(false),
            120000
        );
    }
};
</script>
