/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '../stores'
import router from '../router'
import { bus_setup } from '../utils/event_bus'
import pdfjs from '@/utils/pdfjs_loader'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import PictureView from "/src/components/renderers/PictureView.vue"
import ButtonQRShow from "/src/components/renderers/ButtonQRShow.vue";
import { deep_links } from "./capacitor";

export function registerPlugins(app) {

  // Plugins
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(bus_setup)
    .use(pdfjs)

  // Components
  app.component(VueQrcode.name, VueQrcode)
  app.component("PictureView", PictureView)
  app.component("ButtonQRShow", ButtonQRShow)

  // Capacitor deeplinks
  deep_links(router)
}
