import { backend_api_v2 } from '/src/utils/apiv2'
// import { today } from '/src/utils/time'
import bus from '/src/utils/event_bus'
import base from "./base"
import {
    COMPANY_PR_ALL,
    loadDesignFactory,
    loadActualAndItsDesignFactory,
} from "./selected_helpers.js"
import { defineStore } from 'pinia'
import { useCompanyStore } from "/src/stores/company"
import { useConstructiblesStore } from "/src/stores/constructibles"
import { useAppStore } from './app'

export const useSelectedStore = defineStore('selected', {
    state: () => ({
        // By date
        selected_date: "2000-01-01", // To be touched from component

        // UI MUTATED (as an uuid)
        pr_selection: "all",

        // ASYNC LOADED (as object)
        selected_project: COMPANY_PR_ALL,
        stdlib_mode: false,

        // Constructible selection
        selected_mod: null,
        selected_ass: null,
        selected_modd: null,
        selected_assd: null,

        // Parts
        selected_parts: [],

        selected_hot_issues: [],
    }),
    getters: {
        projectIFCPS(state) {
            const company_store = useCompanyStore()
            return company_store.ifc_ps
                .find(ifcps => ifcps.uuid == state.selected_project?.ifc_parsing_setup)
        },
        areProjectResourcesBusy(state) {
            const pr_res = state.selected_project?.resources
            if (!pr_res) return false
            let to_return = false
            Object.keys(pr_res).forEach((section) => {
                pr_res[section].forEach(f => {
                    if (f.status?.includes("busy")) to_return = true
                })
            })
            return to_return
        },
        annotatedRouteActual(state) {
            const app_store = useAppStore()
            let rn = app_store.route_name
            if (state.selected_mod && ["mod_viewer"].includes(rn)) return { ...state.selected_mod, level: "module" }
            if (state.selected_ass && ["ass_viewer"].includes(rn)) return { ...state.selected_ass, level: "assembly" }
            if (state.selected_modd && ["modd_viewer"].includes(rn)) return { ...state.selected_modd, level: "module" }
            if (state.selected_assd && ["assd_viewer"].includes(rn)) return { ...state.selected_assd, level: "assembly" }
            return null
        },
        annotatedRouteDesign(state) {
            const app_store = useAppStore()
            let rn = app_store.route_name
            if (["mod_viewer", "modd_viewer"].includes(rn)) return state.selected_modd
            if (["ass_viewer", "assd_viewer"].includes(rn)) return state.selected_assd
        },
        routeActualBatch() {
            const constructibles_store = useConstructiblesStore()
            let actual = this.annotatedRouteActual
            return constructibles_store.batches.find(b => b.uuid == actual?.batch)
        },

        // Boolean flags
        isSubassd(state) { !!state.selected_assd?.parent_designs?.length != 0 }
    },
    actions: {
        // By Date
        setDate(date) { this.selected_date = date },

        // UID PR Selection
        setProjectSelection(selection) { this.pr_selection = selection },


        // Project selection
        setProjectMode() { this.stdlib_mode = false },
        setSTDLIBMode() { this.stdlib_mode = true },
        setCompanyWide() { this.selected_project = COMPANY_PR_ALL },
        setProject(data) {
            this.selected_project = data
            if (!("jobs" in this.selected_project.meta)) this.selected_project.meta.jobs = {}
        },
        refreshProject(data) {
            if (data.uuid != this.selected_project.uuid) return
            this.selected_project = data
            if (!("jobs" in this.selected_project.meta)) this.selected_project.meta.jobs = {}
        },
        clearProject() { this.selected_project = null },

        // Operations
        setProjectOperationStatus(payload) {
            const spr = { ...this.selected_project }
            if (!spr.meta) {
                spr.meta = {}
            }
            if (!spr.meta.jobs) {
                spr.meta.jobs = {}
            }
            spr.meta.jobs[payload.job] = payload.meta
            this.selected_project = { ...spr }
        },
        setProjectOperationsEmpty() { this.selected_project.meta.jobs = {} },


        // Constructibles
        setAssembly: base.actions.setter("selected_ass"),
        setModule: base.actions.setter("selected_mod"),
        setAssemblyDesign: base.actions.setter("selected_assd"),
        setModuleDesign: base.actions.setter("selected_modd"),

        // Parts
        setSelectedParts(parts) { this.selected_parts = parts },

        // ISSUES
        setHotIssues(issues) { this.selected_hot_issues = issues },



        // Project Operations
        launchOperationJob(payload) {
            let pr_uuid = payload.project_uuid
            let url_key = this.stdlib_mode ? "standard_libraries" : "projects"
            return backend_api_v2.post(`company/${url_key}/${pr_uuid}/launch_operation/`, payload)
                .then(() => {
                    bus.emit("notification", { text: "Operation launched.", color: "success" })
                    this.setProjectOperationStatus(payload)
                })
                .catch(e => bus.emit("notification", { text: `Can't launch operation: ${e}`, color: "error" }))
        },
        cleanOperationJobs() {
            let pr_uuid = this.selected_project?.uuid
            let url_key = this.stdlib_mode ? "standard_libraries" : "projects"
            return backend_api_v2.post(`company/${url_key}/${pr_uuid}/clean_operations/`)
                .then(() => {
                    bus.emit("notification", { text: "Cleaned all operations.", color: "success", })
                    bus.emit("notification", { timeout: 20000, text: "WARNING: If you cleaned an actually running operation (not stucked) it will continue running anyway and you will see the updated status soon. Don't change any data in the project if you think an operation may be actually running.", color: "warning", })
                    this.setProjectOperationsEmpty()
                })
                .catch(e => bus.emit("notification", { text: `Can't clean operations: ${e}`, color: "error" }))
        },


        // Other Project methods
        loadProject(project_uuid) {
            let url
            if (this.stdlib_mode) url = 'company/standard_libraries/'
            else url = 'company/projects/'
            return backend_api_v2.get(url + project_uuid + '/')
                .then(({ data }) => {
                    this.setProject(data)
                    this.setProjectSelection(data.uuid)
                    return data
                })
                .catch(e => {
                    console.log(`Cant load project: ${e}`)
                })
        },

        putProject(payload) {
            const company_store = useCompanyStore()
            return company_store.putProject(payload)
                .then(({ data }) => {
                    this.setProject(data)
                    return data
                })
        },

        reloadProjectMeta() {
            // ONLY CALL WITH CURRENTLY SELECTED PROJECT UUID!!
            const project_uuid = this.selected_project?.uuid
            return backend_api_v2.get('company/projects/' + project_uuid + '/')
                .then(({ data }) => {
                    this.setProject(data)
                    return data
                })
                .catch(e => console.log(`Cant reload project resources: ${e}`))
        },

        // CONSTRUCTIBLES
        loadAssembly: loadActualAndItsDesignFactory("Assembly", "AssemblyDesign", "constructibles/assemblies"),
        loadModule: loadActualAndItsDesignFactory("Module", "ModuleDesign", "constructibles/modules"),
        loadAssemblyDesign: loadDesignFactory("AssemblyDesign", "constructibles/assembly_designs"),
        loadModuleDesign: loadDesignFactory("ModuleDesign", "constructibles/module_designs"),


        // PARTS
        loadSelectedParts(gids) {
            let level = "assembly"
            let cuuid = this.selected_ass?.uuid
            if (!cuuid) {
                level = "module"
                cuuid = this.selected_mod?.uuid
            }
            if (!cuuid) {
                return  // This is mostly for designs mode
            }
            let gid__in = gids.join(",")
            console.log("LOADING SELECTION", cuuid, gid__in)
            return backend_api_v2.get(`constructibles/parts/?gid__in=${gid__in}&${level}=${cuuid}&serialize_design=true`)
                .then(({ data }) => {
                    this.setSelectedParts(data.results)
                    return data
                })
                .catch(e => {
                    console.log(`Cant load parts: ${e}`)
                })
        },

        // ISSUES
        loadHotIssues: base.actions.loader_query("records/issues/hot", "HotIssues"),
    },
})
