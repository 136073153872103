<template>
    <v-list>
        <!-- INJECT FIRST PANEL HERE -->
        <v-list-subheader v-if="show_first"> {{ t("first_constructible") }} </v-list-subheader>
        <ListItemConstructible :level="first_level" v-if="show_first" :constructible="first" working />
        <v-skeleton-loader class="ml-1 mt-5" type="text" width="50"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-three-line@3"></v-skeleton-loader>
    </v-list>
</template>

<script>
import ListItemConstructible from "./ListItemConstructible.vue"
import { usePitStoreBasics } from "@/stores/app_common";
import { useConstructiblesStore } from "@/stores/constructibles";
import { useViewerStore } from "@/stores/viewer";

export default {
    setup() {
        const store_basics = usePitStoreBasics()
        const viewer_store = useViewerStore()
        const constructibles_store = useConstructiblesStore()
        return { ...store_basics, viewer_store, constructibles_store }
    },
    components: {
        ListItemConstructible
    },
    props: ["loading"],
    computed: {
        designs_mode() {
            return this.viewer_store.designs_mode;
        },
        first() {
            return this.constructibles_store.myFirst()
        },
        first_level() {
            return this.first?.level
        },
        show_first() {
            return !this.designs_mode && this.first
        }
    }
}

</script>