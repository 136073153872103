<template>
    <v-icon class="ml-2" size="small" v-if="msg.not_in_server" color="grey-lighten-3">
        mdi-clock-outline
    </v-icon>
    <v-icon class="ml-2" size="small" v-else-if="msg.readed" color="info-lighten-2">
        mdi-check-all
    </v-icon>
    <v-icon class="ml-2" size="small" v-else-if="msg.delivered" color="grey-lighten-3">
        mdi-check-all
    </v-icon>
    <v-icon class="ml-2" size="small" v-else color="grey-lighten-3">
        mdi-check
    </v-icon>
</template>
<script>
export default {
    props: ["msg"],
};
</script>