<template>
    <v-dialog v-model="dialog" persistent width="500">
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="accent"> <v-icon start> mdi-text-box-check-outline </v-icon>
                SELECT FROM
                REPORT </v-btn>
        </template>
        <v-card :loading="loading ? 'accent' : false">
            <v-card-title>{{ t("select_from_report") }}</v-card-title>
            <v-card-subtitle>{{ t("select_a_report_to_get_its_constructibles_selection") }}</v-card-subtitle>
            <v-card-text v-if="loading">
                <v-skeleton-loader type="list-item@3"></v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
                <v-list>
                    <v-list-item v-for="report in reports" :key="report.uuid" @click="select_report(report)">
                        <v-list-item-title>{{ report.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ report.document_type }}
                            (<Timestamp :model-value="report.modified"> </Timestamp>)
                        </v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="dialog = false">
                    {{ t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script setup>
import Timestamp from "@/components/renderers/Timestamp";
import { ref, watch, computed } from 'vue';
import { usePitStoreBasics } from '@/stores/app_common';
import { useReportsStore } from '@/stores/reports';
import { useAppStore } from "@/stores/app";
import _ from "lodash"


const { t } = usePitStoreBasics();
const reports_store = useReportsStore()
const app_store = useAppStore()
const emit = defineEmits(["selected"])

const dialog = ref(false)
const loading = computed(() => app_store.loader_is_active("Reports"))
const reports = computed(() => reports_store.reports.filter(r => r.document_status == "final"))

const select_report = (report) => {
    emit("selected", _.concat(report.selected_modules, report.selected_assemblies));
}

</script>