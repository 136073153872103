import { backend_api_v2 } from '/src/utils/apiv2'
import { useAppStore } from "/src/stores/app"

export const COMPANY_PR_ALL = {
    uuid: "all",
    name: "Company Wide",
    phases: [],
    meta: {},
}



export function loadDesignFactory(obj_name, url_key) {
    function loadDesign(d_uuid) {
        const app_store = useAppStore()
        app_store.pushLoading("viewer")
        return backend_api_v2.get(`/${url_key}/${d_uuid}/`)
            .then(({ data }) => {
                this['set' + obj_name](data)
                app_store.popLoading("viewer")
            })
            .catch(e => {
                app_store.popLoading("viewer")
                console.log(`Cant load design: ${e}`)
            })
    }
    return loadDesign
}

export function loadActualAndItsDesignFactory(obj_name, obj_name_d, url_key) {
    function loadDesignFromActual(a_uuid) {
        const app_store = useAppStore()
        app_store.pushLoading("viewer")
        return backend_api_v2.get(`/${url_key}/${a_uuid}/`)
            .then(({ data }) => {
                this['set' + obj_name](data)
                app_store.popLoading("viewer")
                return this["load" + obj_name_d](data.design)
            })
            .catch(e => {
                app_store.popLoading("viewer")
                console.log(`Cant load assembly: ${e}`)
            })
    }
    return loadDesignFromActual
}
