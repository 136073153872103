<template>
    <v-dialog v-model="dialog" max-width="770">
        <template v-slot:activator="{ props }">
            <v-list-item v-bind="props">
                <template v-slot:prepend>
                    <v-icon> mdi-swap-horizontal </v-icon>
                </template>
                <v-list-item-title>
                    {{ t("change_company") }}
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card :loading="loading ? 'accent' : false">
            <v-card-title class="text-h5">
                {{ t("change_company") }}
            </v-card-title>
            <v-card-text>
                <v-alert variant="outlined" type="error" class="mx-3">
                    RESTRICTED FEATURE FOR PRODUUZ.IT STAFF
                </v-alert>
            </v-card-text>
            <v-card-text v-if="companies.length == 0 && loading">
                <v-skeleton-loader type="list-item-avatar-three-line@2">
                </v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
                <div class="d-flex ma-3 flex-wrap">
                    <v-card v-for="company in companies" :key="company.uuid" @click="change_company(company.uuid)"
                        border class="ma-1" :color="selected_company == company.uuid ? 'accent' : ''" width="220px">
                        <v-img v-if="company.logo_url" contain height="70" :src="company.logo_url" />
                        <v-avatar height="70" v-else> <v-icon> mdi-factory </v-icon> </v-avatar>
                        <v-card-subtitle class="text-truncate">
                            <v-tooltip location="bottom">
                                <template v-slot:activator="{ props }">
                                    <span v-bind="props">
                                        {{ company.name }}
                                    </span>
                                </template>
                                {{ company.name }}
                            </v-tooltip>
                        </v-card-subtitle>
                    </v-card>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" color="secondary">
                    {{ t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import _ from "lodash"
import { backend_api_v2 } from "/src/utils/apiv2"
import { nameSort } from "/src/utils/basics.js";
// import bus from "/src/utils/event_bus"
import { useAppStore } from "@/stores/app";
import { useSessionStore } from "@/stores/session";
import { useTranslationsStore } from "@/stores/translations";

export default {
    setup() {
        const app_store = useAppStore()
        const session_store = useSessionStore()
        const translations_store = useTranslationsStore()
        const t = translations_store.translate
        return {
            app_store,
            session_store,
            t,
        }
    },
    data: () => ({
        loading: false,
        dialog: false,
        companies: [],
    }),
    computed: {
        selected_company() {
            return this.session_store.user_object?.auth?.company?.uuid
        }
    },
    methods: {
        change_company(company) {
            this.loading = true
            backend_api_v2
                .post("/staff/set_my_company/", { company })
                .then(() => {
                    this.dialog = false
                    this.loading = false
                    this.app_store.initialLoad()
                    this.app_store.refreshBaseUserData(false)
                })
        },
    },
    watch: {
        dialog(d) {
            if (d) {
                this.loading = true
                backend_api_v2
                    .get("/staff/companies/")
                    .then(({ data }) => this.companies = data.sort((a, b) => nameSort(a.name, b.name)))
                    .then(() => {
                        this.loading = false
                    })
            } else {
                this.$emit("close")
            }
        },
    },
}
</script>