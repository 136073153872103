<template>
    <v-list class="fill-height-subtracted" density="compact" lines="two">
        <v-virtual-scroll class="fill-height" v-if="my_list.length != 0" width="400" :items="my_list" :item-height="56">
            <template v-slot:default="{ index, item }">
                <ListItemConstructible :level="item.level" :constructible="item" :key="index" />
            </template>
        </v-virtual-scroll>
        <v-list-subheader v-else>
            {{ t(`no_${level}_for_this_station`) }}
        </v-list-subheader>
    </v-list>
</template>

<style lang="scss" scoped>
.fill-height-subtracted {
    height: calc(100% - 20px);
}
</style>


<script>
import ListItemConstructible from './ListItemConstructible.vue';
import { useConstructiblesStore } from "@/stores/constructibles";
import { usePitStoreBasics } from "@/stores/app_common";

export default {
    setup() {
        const constructibles_store = useConstructiblesStore();
        const store_basics = usePitStoreBasics();

        return {
            ...store_basics,
            constructibles_store,
        };
    },
    components: {
        ListItemConstructible,
    },
    props: ["level"],
    computed: {
        first() {
            return this.constructibles_store.myFirst();
        },
        my_list() {
            return this.constructibles_store.myList();
        },
    },
}
</script>
