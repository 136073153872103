import { useFactoriesStore } from "@/stores/factories";

// WATCHERS THAT LAUNCH ACTIONS FROM FACTORIES STATE CHANGES


export const init_factories_watchers = function () {
    const factories_store = useFactoriesStore()

    factories_store.$subscribe((mutation, state) => {
        console.log("🍍👁️ Empty watchers for factories store.")
    })

}