import { useAppStore } from "/src/stores/app"
import { useSelectedStore } from "/src/stores/selected"
import { useSessionStore } from "/src/stores/session"
import { useViewerStore } from "/src/stores/viewer"


export function state_init(route) {
    console.log("[i] Setting state from url")


    // Get stores
    const session_store = useSessionStore()
    const selected_store = useSelectedStore()
    const app_store = useAppStore()
    const viewer_store = useViewerStore()


    // Get url params
    let project_uuid = route.params.project_uuid;
    let assd_uuid = route.params.assd_uuid;
    let ass_uuid = route.params.ass_uuid;
    let modd_uuid = route.params.modd_uuid;
    let mod_uuid = route.params.mod_uuid;

    // project views
    if (project_uuid) {
        // Select at menu
        selected_store.setProjectSelection(project_uuid)
        if (route.name == "project_viewer") {
            console.log("[i] Setting viewer mode (project viewer). This to be reviewed")

            // viewer_store.buildProjectResourcesAsSelectedWall()   <- NO EXISTE
            app_store.setViewerMode()
            viewer_store.setDesignsMode(true)
        }
        else {
            console.log("[i] Setting manager mode because of route having pr_uuid and not being a viewer route.")
            //  But, if the company has by_date mode, this is wrong I think.
            app_store.setManagerMode()
        }
    }




    // viewer: assembly designs
    else if (assd_uuid) {
        viewer_store.setDesignsMode(true)
        app_store.setViewerMode()
        selected_store.loadAssemblyDesign(assd_uuid)
            .then(() => {
                let pr_uuid = selected_store.selected_assd?.project
                selected_store.loadProject(pr_uuid)
            });
    }

    // viewer assembly
    else if (ass_uuid) {
        viewer_store.setDesignsMode(false)
        app_store.setViewerMode()
        selected_store.loadAssembly(ass_uuid)
            .then(() => {
                let pr_uuid = selected_store.selected_assd?.project;
                selected_store.loadProject(pr_uuid)
            });
    }



    // viewer: module designs
    else if (modd_uuid) {
        viewer_store.setDesignsMode(true)
        app_store.setViewerMode()
        selected_store.loadModuleDesign(modd_uuid)
            .then(() => {
                let pr_uuid = selected_store.selected_modd?.project
                selected_store.loadProject(pr_uuid)
            });
    }

    // viewer module
    else if (mod_uuid) {
        viewer_store.setDesignsMode(false)
        app_store.setViewerMode()
        selected_store.loadModule(mod_uuid)
            .then(() => {
                let pr_uuid = selected_store.selected_modd?.project;
                selected_store.loadProject(pr_uuid)
            });
    }

    else if ([
        "company_settings",
        "company_reports",
        "production_overview",
    ].includes(route.name)) {
        selected_store.setProjectSelection("all")
    }

    // Other views are manager mode, unless a prod user
    else {
        console.log("[i] Setting manager mode here only if not prod user.")
        if (session_store.iam_prod_worker) {
            app_store.setViewerMode()
        }
        else app_store.setManagerMode()

    }
}