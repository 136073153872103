import { Capacitor } from '@capacitor/core';
import BUILD_VERSION from '/src/utils/version';

const IS_ANDROID = Capacitor.getPlatform() === 'android'

const NODE_ENV = process.env.NODE_ENV

const ROOT_API = import.meta.env.VITE_API_URL


let PRODUUZIT_FRONT_ENV = {
    IS_ANDROID,
    BUILD_VERSION,
    NODE_ENV,
    ROOT_API,
}

export default PRODUUZIT_FRONT_ENV
