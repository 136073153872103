import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
import bus from '/src/utils/event_bus'
import _ from "lodash"
// import router from '/src/router'
import { defineStore } from 'pinia'
import { useConstructiblesStore } from "/src/stores/constructibles"
import { useSelectedStore } from "/src/stores/selected"
import { useAppStore } from "/src/stores/app"


export const useQuantitiesStore = defineStore('quantities', {
    state: () => ({
        // Assemblies
        assd_batch_counts: [],
        assd_phase_counts: [],

        // Modules
        modd_batch_counts: [],
        modd_phase_counts: [],

        // State
        generating: false,
        hover_row: false,
        hover_cell: false,
    }),
    getters: {
        // COUNT GETTERS

        // This are the row sums (right column)
        getAssdProjectTotal: getProjectTotalFactory("assd_phase_counts"),
        getModdProjectTotal: getProjectTotalFactory("modd_phase_counts"),
        getAssdPhaseTotal: getPhaseTotalFactory("assd_batch_counts"),
        getModdPhaseTotal: getPhaseTotalFactory("modd_batch_counts"),

        // This is a simple count
        getAssdBatchCount: getCountFactory("assd_batch_counts"),
        getAssdPhaseCount: getCountFactory("assd_phase_counts"),
        getModdBatchCount: getCountFactory("modd_batch_counts"),
        getModdPhaseCount: getCountFactory("modd_phase_counts"),

        // This is for the totals rows.
        getColumnTotal: state => (level, phase, batch) => {
            let count_objs = []
            if (level == "assembly" || level == "all") {
                count_objs.push(...state.assd_phase_counts)
                count_objs.push(...state.assd_batch_counts)
            }
            if (level == "module" || level == "all") {
                count_objs.push(...state.modd_phase_counts)
                count_objs.push(...state.modd_batch_counts)
            }
            count_objs = count_objs.filter(c => c.phase == phase && c.batch == batch?.uuid)
            var count = count_objs.reduce((a, b) => a + b.count, 0);
            return count
        },

        getColumnTotalM2: state => (level, phase, batch) => {
            let count_objs = []
            if (level == "assembly" || level == "all") {
                count_objs.push(...state.assd_phase_counts)
                count_objs.push(...state.assd_batch_counts)
            }
            if (level == "module" || level == "all") {
                count_objs.push(...state.modd_phase_counts)
                count_objs.push(...state.modd_batch_counts)
            }
            count_objs = count_objs.filter(c => c.phase == phase && c.batch == batch?.uuid)
            const constructibles_store = useConstructiblesStore()
            let all_designs = constructibles_store.annotatedDesignsFromLevel(level)
            count_objs = count_objs.map(obj => {
                const design = all_designs.find(design => design.uuid === obj.design);
                return { ...obj, design };
            });
            const total_bruto_area = count_objs.reduce((sum, obj) => {
                const bruto = obj.design?.meta?.metrics?.bruto || 0;
                return sum + (bruto * obj.count);
            }, 0);
            return total_bruto_area;
        },
        getProjectFullCountTotalByLevel: () => level => {
            const selected_store = useSelectedStore()
            const constructibles_store = useConstructiblesStore()
            let designs = constructibles_store.annotatedDesignsFromLevel(level)
            let full_counts = selected_store.selected_project?.meta?.full_counts || {}
            return designs.map(d => d.name).reduce((a, b) => a + full_counts?.[b] || 0, 0)
        },


        // FLAGS
        lockFullMatrix() {
            let lock = false

            const selected_store = useSelectedStore()
            const app_store = useAppStore()

            // lock if there is a job running
            const pr_jobs = selected_store.selected_project?.meta?.jobs || {}
            const LOCKING_OPERATIONS = ["generate_designs", "detect_details"]
            LOCKING_OPERATIONS.forEach(op_name => {
                const op = pr_jobs?.[op_name]
                if (op && op?.perc != 100) {
                    lock = true
                }
            })
            return lock
        },
        lockMatrixNumbers() {
            const app_store = useAppStore()
            let lock = false
            // lock also if system is loading assemblies / designs
            // Otherwise user gets frustrated because he sees the zeroes lol jaja
            if (app_store.loading) lock = true

            if (this.lockFullMatrix) lock = true

            return lock

        }
    },
    actions: {
        // Assemblies
        newAssdBatchCount: newCountFactory("assd_batch_counts"),
        sumOneAssdToBatch: sumFactory("assd_batch_counts", +1),
        subOneAssdToBatch: sumFactory("assd_batch_counts", -1),
        putAssdCountOnBatch: putCountFactory("assd_batch_counts"),

        newAssdPhaseCount: newCountFactory("assd_phase_counts"),
        sumOneAssdToPhase: sumFactory("assd_phase_counts", +1),
        subOneAssdToPhase: sumFactory("assd_phase_counts", -1),
        putAssdCountOnPhase: putCountFactory("assd_phase_counts"),

        setAssdBatchCounts: base.actions.setter("assd_batch_counts"),
        setAssdPhaseCounts: base.actions.setter("assd_phase_counts"),

        // Modules
        newModdBatchCount: newCountFactory("modd_batch_counts"),
        sumOneModdToBatch: sumFactory("modd_batch_counts", +1),
        subOneModdToBatch: sumFactory("modd_batch_counts", -1),
        putModdCountOnBatch: putCountFactory("modd_batch_counts"),

        newModdPhaseCount: newCountFactory("modd_phase_counts"),
        sumOneModdToPhase: sumFactory("modd_phase_counts", +1),
        subOneModdToPhase: sumFactory("modd_phase_counts", -1),
        putModdCountOnPhase: putCountFactory("modd_phase_counts"),

        setModdBatchCounts: base.actions.setter("modd_batch_counts"),
        setModdPhaseCounts: base.actions.setter("modd_phase_counts"),

        // State
        setGenerating: base.actions.setter("generating"),
        setHoverRow: base.actions.setter("hover_row"),
        setHoverCell: base.actions.setter("hover_cell"),

        generateAssembliesFromCounts: generatorFactory(
            "assd_batch_counts",
            "assd_phase_counts",
            "assemblies",
            "constructibles/assemblies/generate/",
        ),
        generateModulesFromCounts: generatorFactory(
            "modd_batch_counts",
            "modd_phase_counts",
            "modules",
            "constructibles/modules/generate/",
        ),


        cleanAssemblyCounters() {
            const constructibles_store = useConstructiblesStore()
            // Assemblies
            let assd_batch_counts = [];
            let assd_phase_counts = [];
            let assemblies = constructibles_store.assemblies
            let batches = constructibles_store.batches
            assemblies.forEach((ass) => {
                let batch = batches.find(
                    (b) => b.uuid == ass.batch
                );
                let phase = batch?.phase || "";
                let assd_uuid = ass.design;

                let c_entry = assd_batch_counts.find(
                    (c_entry) =>
                        c_entry.phase == phase &&
                        c_entry.batch == batch?.uuid &&
                        c_entry.design == assd_uuid
                );
                if (c_entry) {
                    c_entry.count += 1;
                } else {
                    assd_batch_counts.push({ phase, batch: batch?.uuid, design: assd_uuid, count: 1 });
                }

                c_entry = assd_phase_counts.find(
                    (c_entry) => c_entry.phase == phase &&
                        c_entry.design == assd_uuid
                );
                if (c_entry) {
                    c_entry.count += 1;
                } else {
                    assd_phase_counts.push({ phase, design: assd_uuid, count: 1 });
                }
            });
            this.setAssdBatchCounts(assd_batch_counts);
            this.setAssdPhaseCounts(assd_phase_counts);
        },
        cleanModuleCounters() {
            const constructibles_store = useConstructiblesStore()
            // Modules
            let modd_batch_counts = [];
            let modd_phase_counts = [];
            let modules = constructibles_store.modules
            let batches = constructibles_store.batches
            modules.forEach((mod) => {
                let batch = batches.find(
                    (b) => b.uuid == mod.batch
                );
                let phase = batch?.phase || "";
                let modd_uuid = mod.design;

                let c_entry = modd_batch_counts.find(
                    (c_entry) =>
                        c_entry.phase == phase &&
                        c_entry.batch == batch?.uuid &&
                        c_entry.design == modd_uuid
                );
                if (c_entry) {
                    c_entry.count += 1;
                } else {
                    modd_batch_counts.push({ phase, batch: batch?.uuid, design: modd_uuid, count: 1 });
                }

                c_entry = modd_phase_counts.find(
                    (c_entry) => c_entry.phase == phase &&
                        c_entry.design == modd_uuid
                );
                if (c_entry) {
                    c_entry.count += 1;
                } else {
                    modd_phase_counts.push({ phase, design: modd_uuid, count: 1 });
                }
            });
            this.setModdBatchCounts(modd_batch_counts);
            this.setModdPhaseCounts(modd_phase_counts);
        },
        cleanAllCounters() {
            this.cleanAssemblyCounters()
            this.cleanModuleCounters()
        },
        assemblyCountersFromSaveOrClean() {
            const selected_store = useSelectedStore()
            const project = selected_store.selected_project
            if (project?.meta?.quantities?.assd_batch_counts)
                this.setAssdBatchCounts(
                    project?.meta?.quantities?.assd_batch_counts || []
                );
            if (project?.meta?.quantities?.assd_phase_counts)
                this.setAssdPhaseCounts(
                    project?.meta?.quantities?.assd_phase_counts || []
                );
            if (
                !project?.meta?.quantities?.assd_phase_counts ||
                !project?.meta?.quantities?.assd_batch_counts
            )
                this.cleanAssemblyCounters()
        },
        moduleCountersFromSaveOrClean() {
            const selected_store = useSelectedStore()
            const project = selected_store.selected_project
            if (project?.meta?.quantities?.modd_batch_counts)
                this.setModdBatchCounts(
                    project?.meta?.quantities?.modd_batch_counts || []
                );
            if (project?.meta?.quantities?.modd_phase_counts)
                this.setModdPhaseCounts(
                    project?.meta?.quantities?.modd_phase_counts || []
                );

            if (
                !project?.meta?.quantities?.modd_batch_counts ||
                !project?.meta?.quantities?.modd_batch_counts
            )
                this.cleanModuleCounters()
        },
    },
})


export const assemblyCountersFromSaveOrClean = _.debounce(function () {
    const quantities_store = useQuantitiesStore()
    quantities_store.assemblyCountersFromSaveOrClean()
}, 3000)

export const moduleCountersFromSaveOrClean = _.debounce(function () {
    const quantities_store = useQuantitiesStore()
    quantities_store.moduleCountersFromSaveOrClean()
}, 3000)

export const allCountersFromSaveOrClean = _.debounce(function () {
    const quantities_store = useQuantitiesStore()
    quantities_store.assemblyCountersFromSaveOrClean()
    quantities_store.moduleCountersFromSaveOrClean()
}, 3000)


// Getter
function getCountFactory(state_key) {
    let getCount = state => (data) => {
        const design = data.design?.uuid || data.design
        const phase = data.phase
        const batch = data.batch?.uuid || data.batch
        let count_obj = state[state_key].find(
            (c) => c.design == design && c.phase == phase && c.batch == batch
        );
        return count_obj
    }
    return getCount
}

// Action
function sumFactory(state_key, diff) {
    function sumTo(data) {
        const design = data.design?.uuid || data.design
        const phase = data.phase
        const batch = data.batch?.uuid || data.batch
        let c_entry = this[state_key]
            .find(
                c_entry => c_entry.phase == phase &&
                    c_entry.design == design &&
                    c_entry.batch == batch
            )
        c_entry.count += diff
        // Don't go below 0 xD
        if (c_entry.count < 0) c_entry.count = 0

    }
    return sumTo
}

// Action
function newCountFactory(state_key) {
    function newCount(data) {
        const design = data.design?.uuid || data.design
        const phase = data.phase
        const batch = data.batch?.uuid || data.batch
        let count_obj = { phase, batch, design, count: 0 }
        this[state_key].push(count_obj);
        return count_obj
    }
    return newCount
}


// Action
function putCountFactory(state_key) {
    // THIS IS ACTUALLY A PUT-OR-NEW 
    function putCount(data) {
        const design = data.design?.uuid || data.design
        const phase = data.phase
        const batch = data.batch?.uuid || data.batch
        const count = data.count
        let count_obj = this[state_key]
            .find(
                c_entry => c_entry.phase == phase &&
                    c_entry.design == design &&
                    c_entry.batch == batch
            )
        if (count_obj)
            count_obj.count = count
        else
            this[state_key].push({ phase, design, batch, count });
    }
    return putCount
}


// Action
function generatorFactory(store_key_b, store_key_p, actual_name, gen_url) {
    function generator(test_mode) {
        const selected_store = useSelectedStore()
        const constructibles_store = useConstructiblesStore()
        if (test_mode)
            console.log("[i] CONSTRUCTIBLE GENERATOR TEST MODE:")
        else
            console.log("[i] CONSTRUCTIBLE GENERATOR REAL ACTION MODE:")
        let gen_orders = []
        // Comprobar conteos.
        let counts_ok = true
        let d_uuids = this[store_key_b].map(c_entry => c_entry.design)
        let d_uuids_p = this[store_key_p].map(c_entry => c_entry.design)
        d_uuids = [... new Set([...d_uuids, ...d_uuids_p])]
        const phases = selected_store.selected_project.phases

        d_uuids.forEach(d_uuid => {
            phases.forEach(phase => {
                const count1 = this[store_key_b]
                    .filter(c_entry => c_entry.phase == phase &&
                        c_entry.design == d_uuid)
                    .map(c_entry => c_entry.count)
                    .reduce((a, b) => a + b, 0)
                const count2 = this[store_key_p]
                    .filter(c_entry => c_entry.phase == phase &&
                        c_entry.design == d_uuid)
                    .map(c_entry => c_entry.count)
                    .reduce((a, b) => a + b, 0)
                if (count1 != count2) {
                    console.log("[!] Counts wrong", count1, count2)
                    counts_ok = false
                }

            })
        })


        // Comprobar cuántas tenemos y cuantas habría que hacer nuevas.
        let existent_actuals = constructibles_store[actual_name]
            .filter(ass => d_uuids.includes(ass.design))
        console.log(`[i] There are ${existent_actuals.length} existent constructibles.`)

        bus.emit("notification", {
            timeout: 1000,
            text: counts_ok ? "Setup OK." : "Please review your setup.",
            color: counts_ok ? "info" : "error"
        });


        if (!counts_ok) {
            return Promise.reject()
        }

        // Generar las nuevas.
        this[store_key_b].forEach(c_entry => {
            let existent_count = existent_actuals
                .filter(ass => ass.batch == c_entry.batch && ass.design == c_entry.design)
                .length
            let to_be_generated_count = c_entry.count - existent_count
            console.log(`[i] We need to create ${to_be_generated_count} units.`)
            if (to_be_generated_count != 0) {
                gen_orders.push({
                    design: c_entry.design,
                    batch: c_entry.batch,
                    count: to_be_generated_count
                })
            }
        })

        // Notify that nothing changed.
        if (gen_orders.length == 0) {
            bus.emit("notification", {
                timeout: 2000,
                text: "Nothing to do. Your setup doesn't change the existing units.",
                color: "warning",
            });
            return Promise.resolve(gen_orders)
        } else if (test_mode) {
            return Promise.resolve(gen_orders)
        } else {
            return backend_api_v2.post(gen_url, gen_orders)
                .then(({ data }) => {
                    // Notificar.
                    bus.emit("notification", {
                        timeout: 2000,
                        text: `[i] Created a total of ${data.created.length}, and deleted a total of ${data.deleted.length} ${actual_name}`,
                        color: "success"
                    });
                })
                .catch(e => console.log(`Cant generate ${actual_name}: ${e}`))
        }
    }
    return generator
}

// Getter
function getProjectTotalFactory(state_key) {
    let getTotals = state => (design) => {
        let count_objs = state[state_key].filter(c => c.design == design.uuid);
        let count = count_objs.reduce((a, b) => a + b.count, 0);
        return count;
    }
    return getTotals
}


// Getter
function getPhaseTotalFactory(state_key) {
    let getTotals = state => (design, phase) => {
        let count_objs = state[state_key].filter(c => c.design == design.uuid && c.phase == phase);
        let count = count_objs.reduce((a, b) => a + b.count, 0);
        return count;
    }
    return getTotals
}
