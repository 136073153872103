function nameSort(a, b) {
    if ([a, b].includes(undefined)) return 0
    else return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: "base",
    });
}

function by_revision(a, b) {
    return a.revision - b.revision
}

function by_dwg_number(a, b) {
    let na = a.meta?.dwg_number || 0
    let nb = b.meta?.dwg_number || 0
    return na - nb
}


function by_name(a, b) {
    if (a.name > b.name) {
        return 1;
    }
    if (a.name < b.name) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

function by_username(a, b) {
    if (a.username > b.username) {
        return 1;
    }
    if (a.username < b.username) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

function by_short_name_number(a, b) {
    const an = a.short_name.split(" - ")[1]
    const bn = b.short_name.split(" - ")[1]
    if (an > bn) {
        return 1;
    }
    if (an < bn) {
        return -1;
    }
    // a must be equal to b
    return -1;
}

function by_created(a, b) {
    if (a.created > b.created) {
        return 1;
    }
    if (a.created < b.created) {
        return -1;
    }
    // a must be equal to b
    return 0;
}


export {
    nameSort, by_revision, by_dwg_number,
    by_name, by_username, by_short_name_number, by_created
}