<template>
    <v-list subheader>
        <v-list-subheader>CHATS</v-list-subheader>
        <v-list-item v-model="selected_chat" v-if="chats.length != 0">
            <template :key="chat.contact" v-for="(chat, index) in chats">
                <v-list-item :model-value="chat.contact" @click="setSelected(chat.contact)" prepend-icon="mdi-message">
                    <v-list-item-title v-text="chat.contact_name" />
                    <v-list-item-subtitle :class="!chat.last_message.readed &&
            !chat.last_message.contact.is_receiver
            ? 'font-weight-black'
            : ''
            " v-text="chat.last_message.text" />

                </v-list-item>
                <v-divider class="my-0" />
            </template>
        </v-list-item>
        <v-list-item v-else>
            <v-list-item>
                <v-skeleton-loader type="text@3"></v-skeleton-loader>
            </v-list-item>
        </v-list-item>
    </v-list>
</template>

<script>
import { useMailStore } from "@/stores/mail";
import { usePitStoreBasics } from "@/stores/app_common";
export default {
    setup() { return { ...usePitStoreBasics(), mail_store: useMailStore() } },
    props: ["modelValue"],
    data: () => ({ selected_chat: null }),
    computed: {
        chats() {
            return this.mail_store.chats
                .map((chat) => ({
                    ...chat,
                    last_message: chat.messages.slice(-1)[0],
                }))
                .sort(
                    (a, b) =>
                        new Date(b.last_message.created) -
                        new Date(a.last_message.created)
                );
        },
    },
    methods: {
        setSelected(contact_id) {
            this.$emit("update:modelValue", contact_id);
        },
    },
};
</script>