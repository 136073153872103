import { backend_api_v2 } from '/src/utils/apiv2'

import { defineStore } from 'pinia'

export const useTranslationsStore = defineStore('translations', {
    state: () => ({
        langs: {},
        selected_lang: "en",
    }),
    getters: {
        translate: state => key => state.langs[state.selected_lang]?.[key] || key.replaceAll("_", " ")
    },
    actions: {
        setLang(payload) {
            this.langs[payload.lang] = payload.data
        },
        setSelectedLang(lang) { this.selected_lang = lang },

        loadLang(lang) {
            this.setSelectedLang(lang)
            if (lang in this.langs) return
            return backend_api_v2.get('/translations/' + lang)
                .then(({ data }) => {
                    this.setLang({ lang, data })
                })
                .catch(e => console.log(`Cant load lang: ${e}`))
        },
    },
})
