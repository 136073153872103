import bus from "/src/utils/event_bus";
import send_backend_event from "/src/utils/events";

import { init_cloudstorage_watchers } from './cloudstorage'
import { init_company_watchers } from "./company"
import { init_constructibles_watchers } from './constructibles'
import { init_factories_watchers } from './factories'
import { init_records_watchers } from './records'
import { init_reports_watchers } from './reports'
import { init_selected_watchers } from './selected'
import { init_selected_logistics_watchers } from './selected_logistics'
import { init_session_watchers } from './session'
import { init_quantities_watchers } from './quantities'
import { init_viewer_watchers } from './viewer'
import { init_app_watchers } from "./app";


// This is called explicitly from stores/index.js


export default function () {
    // MODULES
    init_app_watchers()
    init_cloudstorage_watchers()
    init_company_watchers()
    init_constructibles_watchers()
    init_factories_watchers()
    init_quantities_watchers()
    init_records_watchers()
    init_reports_watchers()
    init_selected_logistics_watchers()
    init_selected_watchers()
    init_session_watchers()
    init_viewer_watchers()



    // ROOT WATCHERS
    bus.on("back_event", send_backend_event);

}



// CAUTION !!!
// IF YOU ARE LOOKING TO AUTOMATIC DISPATCHERS (ON URL/ROUTE CHANGE)
// TAKE A LOOK AT "asyncStoreDispatcher" IN "router/guards.js"