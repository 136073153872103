<template>
    <v-list density="compact">
        <v-list-subheader>
            {{ t("full_project") }}
        </v-list-subheader>
        <v-list-item :to="('/viewer/project/' + project_uuid)">
            <v-list-item-avatar>
                <v-icon>mdi-home-modern</v-icon>
            </v-list-item-avatar>
            
                <v-list-item-title>
                    {{ t("full_project") }}
                </v-list-item-title>
            
        </v-list-item>
    </v-list>
</template>

<script>
import { usePitStoreBasics } from "@/stores/app_common";
import { useSelectedStore } from "@/stores/selected";
export default {
    setup() {
        const store_basics = usePitStoreBasics()
        const selected_store = useSelectedStore()
        return { ...store_basics, selected_store }
    },
    computed: {
        project_uuid() { return this.selected_store.pr_selection },
    }
}
</script>