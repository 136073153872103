CHATBOX

<template>
    <div class="d-flex flex-row" style="height: 600px">
        <v-col sm="6" md="3" class="d-flex flex-column pa-0 overflow-y-auto">
            <ChatList v-model="selected_chat" />
        </v-col>
        <v-col sm="6" md="9" class="d-flex flex-column pa-0">
            <Chat :contact="selected_chat" />
            <v-spacer></v-spacer>
            <div v-if="selected_chat">
                <v-text-field class="ma-2" v-model="new_message_text" label="Send a message" type="text"
                    autocomplete="off" variant="outlined" append-inner-icon="mdi-send" @keyup.enter="send" @click:append="send"
                    hide-details />
            </div>
        </v-col>
    </div>
</template>

<script>
import ChatList from "/src/components/chat/ChatList";
import Chat from "/src/components/chat/Chat";
import { useMailStore } from "@/stores/mail";
import { usePitStoreBasics } from "@/stores/app_common";
export default {
    setup() { return { ...usePitStoreBasics(), mail_store: useMailStore() } },
    components: { ChatList, Chat },
    data: () => ({
        selected_chat: null,
        new_message_text: "",
    }),
    computed: {
        chat() {
            return this.mail_storegetChatByContactId(
                this.selected_chat
            );
        },
    },
    methods: {
        send() {
            if (this.new_message_text != "") {
                let msg = {
                    text: this.new_message_text,
                    receiver: this.selected_chat,
                    sender: this.selected_chat, // this is placeholder. It's ovewriten at backend
                    // sender_name: this.my_username,
                    created: new Date().toISOString(),
                    delivered: false,
                    readed: false,
                    not_in_server: true,
                    contact: {
                        id: this.selected_chat,
                        is_receiver: true,
                    },
                };
                this.mail_store.sendMessage(msg);
            }
            this.new_message_text = "";
        },
    },
};
</script>