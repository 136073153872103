import { useCloudstorageStore } from "@/stores/cloudstorage";
import { useCompanyStore } from "@/stores/company";
import bus from "/src/utils/event_bus";
import { useSelectedStore } from "@/stores/selected";
import { useConstructiblesStore } from "@/stores/constructibles";
import { useFactoriesStore } from "@/stores/factories";
import { useQuantitiesStore } from "@/stores/quantities";
import { useReportsStore } from "@/stores/reports";

// WATCHERS THAT LAUNCH ACTIONS FROM REPORTS STATE CHANGES

let REPORT_TIMEOUTS_QUEUE = []
let last_reports = []

export const init_reports_watchers = function () {
    const company_store = useCompanyStore()
    const cloudstorage_store = useCloudstorageStore()
    const factories_store = useFactoriesStore()
    const selected_store = useSelectedStore()
    const quantities_store = useQuantitiesStore()
    const reports_store = useReportsStore()

    reports_store.$subscribe((mutation, state) => {

        const reports = state.reports
        if (reports != last_reports) {
            reports
                // Only reports that are working or waiting
                .filter(r => ["working", "waiting"]
                    .some((s) => r.last_file?.meta?.status.includes(s))
                )
                .forEach(r => {
                    // If it's not in the queue, launch timeout
                    if (!REPORT_TIMEOUTS_QUEUE.includes(r.uuid)) {
                        // Add to the queue
                        REPORT_TIMEOUTS_QUEUE.push(r.uuid)
                        setTimeout(() => {
                            // On execution of timeout, remove from queue
                            REPORT_TIMEOUTS_QUEUE = REPORT_TIMEOUTS_QUEUE.filter(q => q != r.uuid)
                            reports_store.refreshReport(r.uuid)
                        },
                            3000)
                    }
                })
            last_reports = [...reports]
        }
    })

}