<template>
    <div class="fill-height">
        <SelectorProductionDate class="mx-auto mb-2" :wide="true" v-if="show_by_date_manager" />
        <ListSelector v-if="show_list_selector" />
        <v-divider v-if="show_list_selector" />
        <div v-if="show_constructibles" :class="show_list_selector ? 'fill-height-subtracted' : 'fill-height'">
            <ListConstructibles />
        </div>
        <ListFullProject v-if="show_proj_view" />
        <ListModuleDesigns v-if="show_modds" />
        <ListAssemblyDesigns v-if="show_assds" />
        <ListSkeleton v-if="loading" />
        <v-list-subheader v-if="no_constructibles_flag"> {{ t('no_const_to_show') }} </v-list-subheader>
        <v-list-subheader v-if="no_designs_flag"> {{ t('no_design_to_show') }} </v-list-subheader>
        <div v-if="show_modds || show_assds" class="my-10">
            -
        </div>
    </div>
</template>

<style lang="scss" scoped>
.fill-height-subtracted {
    height: calc(100% - 48px);
}
</style>


<script>
import SelectorProductionDate from "../SelectorProductionDate";
import ListSelector from './ListSelector.vue'
import ListSkeleton from './ListSkeleton.vue'
import ListConstructibles from './ListConstructibles.vue'
import ListFullProject from './ListFullProject.vue'
import ListModuleDesigns from './ListModuleDesigns.vue'
import ListAssemblyDesigns from './ListAssemblyDesigns.vue'
import { useFullPitStore } from "@/stores/app_common";
export default {
    setup() {
        const pit_store = useFullPitStore()
        return { ...pit_store }
    },
    components: {
        ListSelector,
        ListSkeleton,
        ListConstructibles,
        ListFullProject,
        ListModuleDesigns,
        ListAssemblyDesigns,
        SelectorProductionDate,
    },
    computed: {
        prod_worker() {
            return this.session_store.iam_prod_worker
        },
        designs_mode() {
            return this.viewer_store.designs_mode && !this.prod_worker
        },
        show_list_selector() {
            return !this.prod_worker
        },
        loading() {
            return this.app_store.active_loaders.includes("project_resources")
        },
        show_constructibles() {
            let have_mods = this.constructibles_store.haveModules
            let have_asses = this.constructibles_store.haveAssemblies
            return (have_mods || have_asses) && !this.designs_mode && !this.loading
        },
        show_proj_view() {
            let have = this.cloudstorage_store.hasFullProjectResources
            return have && this.designs_mode && !this.loading
        },
        show_modds() {
            let have = this.constructibles_store.haveModuleDesigns
            return have && this.designs_mode && !this.loading
        },
        show_assds() {
            let have = this.constructibles_store.haveAssemblyDesigns
            return have && this.designs_mode && !this.loading
        },
        no_constructibles_flag() {
            return !this.loading && !this.designs_mode && !this.show_constructibles
        },
        no_designs_flag() {
            return !this.loading && this.designs_mode && !this.show_assds && !this.show_modds
        },
        selected_project_uuid() {
            return this.selected_store.selected_project?.uuid || "";
        },
        company() {
            return this.session_store.user_object?.auth?.company;
        },
        company_wide() {
            return this.selected_project_uuid == "all";
        },
        show_by_date_manager() {
            return !this.prod_worker && this.company?.work_by_date && this.company_wide
        },
    }
}
</script>