import mitt from 'mitt';

let the_bus = mitt()

export const bus_setup = {
    install: (app, options) => {
        app.config.globalProperties.$bus = the_bus;
    }
}

export default the_bus

// In vue components:
// this.$bus.emit("event")
// this.$bus.emit("event", data)
// this.$bus.on("event", data => this.doThing(data))

// Outside vue components:
// import bus from '/src/utils/event_bus'
// bus.emit("event", data)
// bus.on("event", data => this.doThing(data))
