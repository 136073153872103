import { useCloudstorageStore } from "@/stores/cloudstorage";
import { useCompanyStore } from "@/stores/company";

// WATCHERS THAT LAUNCH ACTIONS FROM COMPANY STATE CHANGES

let last_company_uuid = null

export const init_company_watchers = function () {
    const company_store = useCompanyStore()
    const cloudstorage_store = useCloudstorageStore()

    company_store.$subscribe((mutation, state) => {


        // Reload project and company packages
        let uuid = state.company?.uuid
        if (last_company_uuid != uuid) {
            if (uuid != undefined)
                cloudstorage_store.loadCompanyPackages(uuid)
            cloudstorage_store.set_company_packages([])
            last_company_uuid = uuid
        }





    })

}