<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ props }">
            <v-list-item v-if="listitem != undefined" v-bind="props">
                <template v-slot:prepend>
                    <v-icon> mdi-web </v-icon>
                </template>
                <v-list-item-title>
                    lang
                </v-list-item-title>
            </v-list-item>
            <v-btn v-else-if="icon != undefined" v-bind="props" icon="mdi-web" variant="text">
            </v-btn>
            <v-btn v-else v-bind="props" variant="text" color="accent">
                {{ t("language") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                {{ t("language") }}
            </v-card-title>

            <v-card-text>
                {{ t("select_lang") }}
                <br />
                <br />
                <SelectLanguage :allow_default="true" v-model="language" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" :loading="loading" color="warning" @click="save">
                    {{ t("save") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { useSessionStore } from "@/stores/session";
import SelectLanguage from "../account/SelectLanguage.vue";
import { backend_api_v2 } from "/src/utils/apiv2";
import { usePitStoreBasics } from "@/stores/app_common";
import { mapState, mapActions } from "pinia";

export default {
    setup() { return { ...usePitStoreBasics() } },
    props: ["icon", "listitem"],
    components: { SelectLanguage },
    data: () => ({
        loading: false,
        dialog: false,
        language: "",
    }),
    computed: {
        ...mapState(useSessionStore, { user: "user_object" }),
    },
    methods: {
        ...mapActions(useSessionStore, ["getUserObject"]),
        save() {
            this.loading = true;
            return backend_api_v2
                .post("/my_user/change_language/", { language: this.language })
                .then(() => {
                    this.dialog = false;
                    this.getUserObject()
                })
                .catch((e) => console.log(`Cant change lang: ${e}`))
                .finally(() => (this.loading = false));
        },
    },
    mounted() {
        this.language = this.user.auth.language;
    },
};
</script>