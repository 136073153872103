import { backend_api_v2 } from '/src/utils/apiv2'
import bus from '/src/utils/event_bus'
import { defineStore } from 'pinia'
import { useAppStore } from "/src/stores/app"
import { useTranslationsStore } from './translations'
import vuetify from '/src/plugins/vuetify';


function determineUserLanguage(auth) {
    if (auth.multilang && auth.language) {
        return auth.language || "en"
    } else
        return auth.company.meta.language || "en"
}


export const useSessionStore = defineStore('session', {
    state: () => ({
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        authenticated: !!localStorage.getItem('access_token'),
        user_object: {
            _flag: "not-initialized",
            username: "",
            auth: {}
        }
    }),
    getters: {
        viewer_show_qr: state => state.user_object?.auth?.company?.viewer_show_qr || true,
        viewer_show_rfid: state => state.user_object?.auth?.company?.viewer_show_rfid || true,
        viewer_hide_hidden_materials: state => state.user_object?.auth?.company?.viewer_hide_hidden_materials || true,
        work_by_date: state => state.user_object?.auth?.company?.work_by_date || false,
        force_checklist_start: state => state.user_object?.auth?.company?.force_checklist_start || false,
        kitted_parts_behavior: state => state.user_object?.auth?.company?.kitted_parts_behavior || "always_kitted",
        my_selected_station: state => state.user_object.auth?.selected_production_station_name,
        my_selected_station_object: state => {
            let sta_name = state.user_object.auth?.selected_production_station_name
            let sta_obj = state.user_object.auth?.selected_production_line_obj?.stations
                .find(sta => sta.name == sta_name)
            return sta_obj
        },
        my_selected_line_object: state => state.user_object.auth?.selected_production_line_obj,
        my_line_tipos: state => state.user_object.auth?.selected_production_line_obj.tipos,
        my_line_uuid: state => state.user_object.auth?.production_line,
        iam_prod_worker: state => state.user_object.auth?.production_user,
        iam_admin: state => state.user_object.auth?.company_admin,
        my_company: state => state.user_object.auth?.company,
        androidLicensed: state => state.user_object.auth?.licenses?.includes('android'),
    },
    actions: {
        setTokens(data) {
            this.access_token = data.access
            this.refresh_token = data.refresh
            this.authenticated = true
            localStorage.setItem('access_token', this.access_token)
            localStorage.setItem('refresh_token', this.refresh_token)
        },
        refreshToken(at) {
            this.access_token = at
            localStorage.setItem('access_token', this.access_token)
        },
        cleanSession() {
            this.access_token = ""
            this.refresh_token = ""
            this.authenticated = false
            localStorage.setItem('access_token', "")
            localStorage.setItem('refresh_token', "")
        },
        setUserObject(data) {
            this.user_object = data
        },

        login(data) {
            const app_store = useAppStore()
            return backend_api_v2.post('/my_user/token/', data)
                .then(({ data }) => {
                    this.setTokens(data)
                    app_store.refreshBaseUserData(true)
                })
        },
        refresh() {
            const app_store = useAppStore()
            console.log("[i] Refreshing access token")
            if (this.refresh_token == "") bus.emit("events/session/logout")
            return backend_api_v2.post('/my_user/token/refresh/', { refresh: this.refresh_token })
                .then(({ data }) => {
                    this.refreshToken(data.access)
                    app_store.refreshBaseUserData(true)
                })
                .catch(() => {
                    bus.emit("notification", {
                        timeout: 1000,
                        text: "Failed to refresh session. You have to login.",
                        color: "error"
                    });
                })
        },
        getUserObject() {
            const translations_store = useTranslationsStore()
            return backend_api_v2.get('/my_user/')
                .then(({ data }) => {
                    this.setUserObject(data)
                    const locale = determineUserLanguage(data.auth)
                    vuetify.locale.current.value = locale
                    translations_store.loadLang(locale)
                })
                .catch(e => console.log(`Cant get User Object: ${e}`))
        },
        postUserSelectedStation(uuid) {
            return backend_api_v2.post('/my_user/select_station/', { selected_station_uuid: uuid })
                .then(({ data }) => {
                    this.setUserObject(data)
                })
                .catch(e => console.log(`Cant post user station: ${e}`))
        }
    },
})
