import { useRecordsStore } from "@/stores/records";

// WATCHERS THAT LAUNCH ACTIONS FROM RECORDS STATE CHANGES


export const init_records_watchers = function () {
    const records_store = useRecordsStore()

    records_store.$subscribe((mutation, state) => {
        console.log("🍍👁️ Empty watchers for records store.")
    })

}