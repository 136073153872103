<template>
    <span :class="{ 'redbox': old, 'bluebox': !old, 'white--text boxed ma-1': true }">{{ rev }}</span>
</template>
<style scoped>
.boxed {
    font-size: 0.7em;
    border-radius: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
}

.redbox {
    background-color: rgba(253, 0, 0, 0.463);

}

.bluebox {
    background-color: rgba(42, 163, 255, 0.696);

}
</style>
<script>
export default {
    props: ["rev", "old"],
}
</script>