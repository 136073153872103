/**
 * main.js
 *
 * Bootstraps plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'
import { initSentry } from '@/plugins/sentry'
import initialize_watchers from "/src/store_watchers"

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

const app = createApp(App)

if (process.env.NODE_ENV !== 'development') initSentry(app)

registerPlugins(app)

app.mount('#app')

// Inits our own watchers
initialize_watchers()