import { useSelectedStore } from "@/stores/selected";
import { useViewerStore } from "@/stores/viewer";

// WATCHERS THAT LAUNCH ACTIONS FROM VIEWER STATE CHANGES


let last_selected_parts_ifc_ids = []


export const init_viewer_watchers = function () {
    const selected_store = useSelectedStore()
    const viewer_store = useViewerStore()

    viewer_store.$subscribe((mutation, state) => {

        console.log("🍍👁️ Viewer store watchers for selected parts.")
        const selected_parts_ifc_ids = state.selected_parts_ifc_ids

        if (selected_parts_ifc_ids != last_selected_parts_ifc_ids) {
            selected_store.setSelectedParts([])
            if (selected_parts_ifc_ids.length != 0) selected_store.loadSelectedParts(selected_parts_ifc_ids)

            last_selected_parts_ifc_ids = [...selected_parts_ifc_ids]
        }
    })
}
