<template>
    <div class="d-flex flex-column align-space-between mb-1 ml-1 mr-1" v-if="show">
        <v-btn icon variant="text" v-if="icon" @click="dialog = true">
            <v-icon>mdi-qrcode</v-icon>
        </v-btn>
        <v-btn size="small" color="secondary" @click="dialog = true" v-else>
            <v-icon start>mdi-qrcode</v-icon>QR
        </v-btn>
        <v-dialog v-model="dialog" width="400">
            <v-card>
                <v-card-title class="text-h5"> {{ t("qr_code") }} </v-card-title>
                <v-card-subtitle v-if="actual_mode">{{ t("wall_unit") }}:
                    {{ actual_uuid.substring(0, 8) }}</v-card-subtitle>
                <v-card-subtitle v-if="phase_mode">{{ t("phase") }}:
                    {{ phase }} </v-card-subtitle>
                <v-card-subtitle v-if="batch_mode">{{ t("batch") }}:
                    {{ batch.name }}</v-card-subtitle>
                <v-card-text>
                    <vue-qrcode :value="url" :options="{ width: 350 }" tag="img" id="qr-image"></vue-qrcode>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="printQR" color="accent">
                        <v-icon start>mdi-printer</v-icon>
                        {{ t("print") }}
                    </v-btn>
                    <v-btn @click="dialog = false" color="secondary">{{
        t("close")
                        }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { usePitStoreBasics } from "@/stores/app_common";
export default {
    setup() { return { ...usePitStoreBasics(), } },
    name: "ButtonQRShow",
    props: ["actual", "project", "phase", "batch", "icon"],
    data: () => ({
        dialog: false,
    }),
    computed: {
        url() {
            // if (this.phase_mode) {
            //     let href = window.location.href;
            //     let url = href.replace(this.$route.path, "");
            //     return url + "/HERE_ADD_BATCH_ROUTE!!!!!";
            // } else {
            return window.location;
            // }
        },
        show() {
            return this.phase == undefined || this.phase != "0";
        },
        actual_mode() {
            return this.actual != undefined;
        },
        actual_uuid() {
            return this.actual?.uuid || ""
        },
        phase_mode() {
            return (
                this.project != undefined &&
                this.phase != undefined &&
                this.phase != "0"
            );
        },
        batch_mode() {
            return this.batch != undefined;
        },
    },
    methods: {
        printQR() {
            console.log(document.getElementById("qr-image").outerHTML);
            let popup = window.open("", "_blank");
            popup.document.body.innerHTML =
                document.getElementById("qr-image").outerHTML;
            popup.focus(); //required for IE
            popup.print();
        },
    },
};
</script>